import { IonHeader, IonText, IonRouterLink } from "@ionic/react"
import React from "react"
import "./Header.scss"
import { useHistory } from "react-router"
import { type SkipLink } from "types/common"
import logo from "../theme/images/Groupe_2960.png"
import { useAuth, useWindowSize } from "../utils/hooks"
import SkipLinks from "./SkipLinks"
import DarkToggle from "./DarkToggle"

const skipLinks: SkipLink[] = [
  { linkName: "content", linkTitle: "contenu", id: "header1" },
  { linkName: "footer", linkTitle: "bas de page", id: "header2" },
]

function Header() {
  const router = useHistory()
  const size = useWindowSize()

  const goHome = (e: any) => {
    if (router.location.pathname === "/login") {
      e.preventDefault()
    }
  }

  const auth = useAuth()

  return (
    <>
      {size.width > 1135 ? <SkipLinks skipLinks={skipLinks} /> : null}
      <IonHeader className="header">
        <div className="container_logo">
          <IonRouterLink
            routerLink={auth.access_token ? "/dashboard" : "/login"}
            routerDirection="back"
            onClick={goHome}
          >
            <img className="header_logo" src={logo} alt="Logo Vilogia, retour à la page d'accueil" sizes="120px" />
          </IonRouterLink>
          <div className="espace-loc_text">
            <IonText>
              <p>
                Espace
                <br />
                <span className="span_loc">Locataire</span>
              </p>
            </IonText>
          </div>
        </div>
        <DarkToggle />
      </IonHeader>
    </>
  )
}

export default Header
