import { IonPage, IonContent, IonText } from "@ionic/react"
import { resetEmailConfirm } from "api/account"
import { logout } from "api/helper"
import Footer from "components/Footer"
import Header from "components/Header"
import BuildLayout from "layouts/BuildLayout"
import React, { useEffect, useRef } from "react"
import { useHistory, useLocation } from "react-router"
import { toast } from "react-toastify"
import { Container } from "reactstrap"
import "./ResetMailValidation.scss"
import { useRequireAuth } from "utils/hooks"

export default function ResetMailValidation() {
  const auth = useRequireAuth()
  const isMounted = useRef(true)
  const { pathname } = useLocation()
  const navigate = useHistory().push
  const validationCodeVerification = pathname.split("/").at(-1)?.match("validationCode=")
  const validationCode = pathname.split("/").at(-1)!.split("validationCode=").at(-1)

  async function changeEmail(code: string) {
    toast.loading("Veuillez patienter...", { toastId: "resetMailValidationLoading" })
    try {
      const res = await resetEmailConfirm(code)
      console.log("🚀 ~ useEffect ~ res:", res)
      toast.success(`${res.data.message}. Veuillez vous reconnecter.`)
      logout()
    } catch (error: any) {
      console.error("🚀 ~ useEffect ~ error:", error) // TODO: remove the logs
      if (error.response?.status > 400) {
        navigate("/", { replace: true })
        toast.error("Il y eu une erreur. Veuillez réessayer.", { toastId: "errorToast" })
      }
    }
    toast.dismiss("resetMailValidationLoading")
    navigate("/", { replace: true })
  }

  useEffect(() => {
    if (isMounted.current) {
      if (!auth.access_token) {
        toast.error("Il y eu une erreur. Veuillez vous connecter pour effectuer cette action.")
        navigate("/", { replace: true })
      }
      if (auth.access_token && validationCode && validationCodeVerification) {
        changeEmail(validationCode)
      }
    }
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <IonPage>
      <Header />
      <IonContent role="main">
        <BuildLayout>
          <Container disconnect="true">
            <IonText>
              <p className="lost-password-confirmation">
                Confirmation de votre tentative de modification de l&apos;adresse email associée à votre compte en
                cours...
              </p>
            </IonText>
          </Container>
        </BuildLayout>
        <Footer />
      </IonContent>
    </IonPage>
  )
}
