import { API } from "./axioshelper"
import { type Document } from "../types/common"

export async function fetchDocuments(category: null | string, year: null | string) {
  return API.request<Document[]>({
    method: "get",
    url: "document/list",
    params: {
      category,
      year,
    },
  }).then((response) => response.data)
}

export async function downloadDoc(documentId: string) {
  return API.request({
    method: "get",
    url: `/document/download/${documentId}`,
    responseType: "blob",
  })
}

export async function downloadSepa() {
  return API.request({
    method: "get",
    url: "/profile/b64sepa",
    responseType: "blob",
  })
}

export async function fetchAsideInfos() {
  return API.request({
    method: "get",
    url: "/document/helpers",
  }).then((response) => response.data)
}
