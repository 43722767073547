import { type Config } from "../types/common"
import { API } from "./axioshelper"

export async function FetchConfig() {
  return API.request<Config>({
    method: "get",
    url: "config",
    params: {
      token: process.env.REACT_APP_ANONYMOUS_TOKEN,
    },
  }).then((res: any) => res.data)
}

export async function impersonate(token: string) {
  return await API.request({
    method: "post",
    url: "impersonate",
    headers: {
      "Content-Type": "multipart/form-data;",
      Authorization: `Bearer ${token}`,
    },
  })
}

export default FetchConfig
