import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { RentInfos } from "../types/common"
import { fetchRentInfos } from "../api/rent"

export function useRent(): UseQueryResult<RentInfos> {
  return useQuery({
    queryKey: ["rent"],
    queryFn: async () => fetchRentInfos(),
  })
}

export default useRent
