import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { type Reducer } from "react"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./reducers"
import migrations from "./migrations/migrations"

const persistConfig = {
  key: "root",
  version: 2,
  storage,
  whitelist: ["auth", "user", "introInfos", "notif"],
  migrate: createMigrate(migrations, { debug: true }),
}

const persistedReducer: Reducer<any, any> = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
export default store
