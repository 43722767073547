import { API } from "./axioshelper"
import { New } from "../types/common"

export async function fetchNews(pageParam: number) {
  return await API.request<New[]>({
    method: "get",
    url: `news?page=${pageParam}`,
  }).then((response) => response.data)
}

export async function fetchNew(id: number) {
  return await API.request({
    method: "get",
    url: `news/${id}`,
  }).then((response) => response.data)
}
