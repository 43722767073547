import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import AuthLayout from "../../layouts/AuthLayout"

import Footer from "../../components/Footer"
import DocumentsList from "../../components/DocumentsList"
import Select from "../../components/Select"
import AsideInfos from "../../components/AsideInfos"

import "./Documents.scss"
import { useAsideInfo, useDocuments } from "../../hooks/documents"
import { Filter } from "../../types/common"

function Documents() {
  const { pathname } = useLocation()

  const { data: asideInfos, isLoading: asideIsLoading } = useAsideInfo()
  const [dateFilter, setdateFilter] = useState<null | string>(null)
  const [categoryFilter, setcategoryFilter] = useState<null | string>(null)
  const [years, setYears] = useState<string[]>([])
  const [categories, setCategories] = useState<string[]>([])

  const selectAllDate: Filter[] = [{ value: null, label: "Toutes" }]
  const selectAllCategory: Filter[] = [{ value: null, label: "Tous les documents" }]
  let yearOptions = years.map((year): Filter => ({ value: year, label: year }))
  yearOptions = selectAllDate.concat(yearOptions)
  let categoryOptions = categories.map(
    (category): Filter => ({
      value: category,
      label: category,
    })
  )
  categoryOptions = selectAllCategory.concat(categoryOptions)

  const { data: documents } = useDocuments(categoryFilter, dateFilter)
  if (undefined !== documents && documents.length !== 0) {
    documents.forEach((document) => {
      if (!years.includes(document.year)) {
        setYears([...years, document.year])
      }
    })
  }

  if (undefined !== documents && documents.length !== 0) {
    documents.forEach((document) => {
      if (!categories.includes(document.category)) {
        setCategories([...categories, document.category])
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Mes documents - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="La page mes documents rassemble vos papiers administratifs sous la forme d'une liste organisée, dont les éléments sont téléchargeables."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="documents__content">
          <div className="container-section-width--padded">
            <h1 className="documents__title">Mes documents</h1>

            <div className="select-document__container">
              <Select
                className="select__date"
                id="date"
                name="date"
                options={yearOptions}
                placeholder="Toutes"
                onChange={(e) => {
                  setdateFilter(e.detail.value ?? null)
                }}
                value={dateFilter}
              />
              <Select
                className="select__operation"
                id="operation"
                name="operation"
                options={categoryOptions}
                placeholder="Touts les documents"
                onChange={(e) => {
                  setcategoryFilter(e.detail.value ?? null)
                }}
                value={categoryFilter}
              />
            </div>

            <div className="documents__main">
              <div className="documents_list">
                {undefined !== documents && <DocumentsList documents={documents} sorted />}
              </div>

              <aside>{!asideIsLoading && undefined !== asideInfos && <AsideInfos asideInfos={asideInfos} />}</aside>
            </div>
          </div>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}

export default Documents
