import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { Agency, Manager } from "../types/common"
import { fetchAccountManager, fetchAgencyInfos } from "../api/contact"

export function useAgencyInfos(): UseQueryResult<Agency> {
  return useQuery({
    queryKey: ["agency"],
    queryFn: async () => fetchAgencyInfos(),
  })
}

export function useManagerInfos(): UseQueryResult<Manager> {
  return useQuery({
    queryKey: ["manager"],
    queryFn: async () => fetchAccountManager(),
  })
}

export default useAgencyInfos
