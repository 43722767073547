import React, { useCallback, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import "./ModalContract.scss"
import { UserContract } from "types/common"
import { useEventListener } from "utils/hooks"
import FocusTrap from "focus-trap-react"
import { useHistory } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import { patchContractInfos } from "../api/userContract"
import { useAccount } from "../hooks/user"

type ModalContractProps = {
  onClose(): void
  contractsList: UserContract
}

function ModalContract({ onClose, contractsList }: ModalContractProps) {
  const { data: user } = useAccount()
  const ref: any = useRef()
  const contractInputRef: any = useRef(new WeakMap())
  const history = useHistory()

  useEffect(() => {
    ref.current.focus()
  }, [])

  if (undefined === user) {
    return null
  }

  const [contract, setcontract] = useState<string>(user.contract)

  const queryClient = useQueryClient()

  const handleClick = () => {
    patchContractInfos(contract)
      .then(async () => {
        await queryClient.invalidateQueries()
        onClose()
        history.push("/dashboard", { refetch: true })
      })
      .catch((err) => {
        console.error(err)
        toast.error("Nous ne pouvons traiter votre demande pour le moment. Réessayez plus tard.")
      })
  }

  // Close modal contract on escape
  const handleClose = useCallback((e: any) => {
    if (e.key === "Escape") {
      onClose()
    }
  }, [])

  useEventListener("keydown", handleClose)

  return (
    <div className="background__modal">
      <FocusTrap>
        <div className="container__modal--contract" tabIndex={0}>
          <button type="button" className="close_modal" onClick={onClose} ref={ref}>
            Fermer
          </button>

          <div>
            <h1>Vos comptes clients</h1>
            <fieldset className="list_contract">
              <legend className="sr-only">Choisir un compte client :</legend>
              {contractsList.map((data, index) => (
                <React.Fragment key={data.contractId}>
                  <input
                    tabIndex={-1}
                    ref={(el) => contractInputRef.current.set(data, el)}
                    id={`contract${index}`}
                    type="radio"
                    name="contract"
                    value={data.contractId}
                    onChange={() => {
                      setcontract(data.contractId)
                    }}
                    defaultChecked={user.contract === contractsList[index].contractId}
                  />
                  <label // replace with button
                    htmlFor={`contract${index}`}
                    tabIndex={0}
                    onClick={() => {
                      setcontract(data.contractId)
                    }}
                    onKeyUp={(e: any) => {
                      if (e.code === "Space") {
                        setcontract(data.contractId)
                      }
                    }}
                  >
                    <p>
                      Compte client n°
                      {data.contractId}
                      <span className={data.state === "SORTI" ? "status__dette" : ""}>{data.state}</span>
                    </p>
                  </label>
                </React.Fragment>
              ))}
            </fieldset>

            <button type="button" className="send__button" onClick={handleClick}>
              voir ce compte client
            </button>
          </div>
        </div>
      </FocusTrap>
    </div>
  )
}

export default ModalContract
