const disconnectMigration = (state: any) => ({
  ...state,
  auth: {
    ...state.auth,
    access_token: null,
    refresh_token: null,
  },
})

export default disconnectMigration
