import { IonIcon, isPlatform } from "@ionic/react"
import React from "react"

import "./Footer.scss"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setIsSliderOpen } from "stores/actions/themeActions"
import logoApple from "../theme/images/logo_apple.svg"
import logoPlayStore from "../theme/images/logo_playStore.svg"
import { useAuth } from "../utils/hooks"

function Footer() {
  const auth = useAuth()
  const dispatch = useDispatch()

  const handleOpenSlider = () => {
    dispatch(setIsSliderOpen())
  }

  let classname = "footer"

  if (auth.access_token) {
    classname += " footer--light"
  }

  return (
    <footer className={classname} id="footer" role="contentInfo">
      <ul className="main_footer container-width--padded">
        <li>
          <Link to="/sitemap" aria-label="Lien vers la page du plan du site">
            Plan du site
          </Link>
        </li>
        <li>
          <Link to="/accessibility" aria-label="Lien vers la page d'accessibilité">
            Accessibilité : partiellement conforme
          </Link>
        </li>

        {auth.access_token && (
          <li>
            <button type="button" className="open__slider" onClick={handleOpenSlider}>
              Présentation des fonctionnalités
            </button>
          </li>
        )}

        <li>
          <Link to="/cgu" className="link_CGU" aria-label="Lien vers la page de conditions générales d'utilisation">
            Conditions générales d&apos;utilisation
          </Link>
        </li>

        <li>
          <Link to="/pdc" aria-label="Lien vers la page de politique de confidentialité">
            Politique de confidentialité
          </Link>
        </li>

        <li>
          <a href="https://www.vilogia.fr/" aria-label="Lien vers la page d'accueil de Vilogia.fr">
            Vilogia.fr
          </a>
        </li>

        {!isPlatform("capacitor") ? (
          <>
            <li className="dlApp">Téléchargez l&apos;application :</li>

            <li>
              <IonIcon icon={logoApple} aria-hidden="true" className="footer-icon" />
              <a
                href="https://apps.apple.com/fr/app/espace-locataire-vilogia/id1456731920?platform=iphone"
                target="_blank"
                rel="noreferrer"
                aria-label="Lien vers le téléchargement de l'application sur l'app store"
              >
                App Store
              </a>
            </li>
            <li>
              <IonIcon icon={logoPlayStore} aria-hidden="true" className="footer-icon" />
              <a
                href="https://play.google.com/store/apps/details?id=fr.vilogia.locataire.android&gl=FR"
                target="_blank"
                rel="noreferrer"
                aria-label="Lien vers le téléchargement de l'application sur le google play store"
              >
                Google Play
              </a>
            </li>
          </>
        ) : null}
      </ul>
    </footer>
  )
}

export default Footer
