const newsState: { showModale: boolean } = {
  showModale: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const NewsReducer = (state = newsState, action: { type: string; data?: boolean | undefined }): any => {
  switch (action.type) {
    case "SHOW_MODALE_NO_NEWS":
      return {
        ...state,
        showModale: action.data,
      }

    default:
      return state
  }
}

export default NewsReducer
