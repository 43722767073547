import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Footer from "../../components/Footer"
import AuthLayout from "../../layouts/AuthLayout"
import AccomodationList from "../../components/AccomodationList"
import "./Accomodation.scss"
import { useAllHomeDetails } from "../../hooks/myHome"

function Accomodation() {
  const { pathname } = useLocation()
  const { data: allHomeDetails, isLoading: isHomeDetailsLoading } = useAllHomeDetails()

  return (
    <>
      <Helmet>
        <title>Mon logement - Espace locataire - Vilogia</title>
        <meta name="description" content="La page mes locations rapporte les détails de vos locations." />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="accomodation_container">
          <section className="container-section-width--padded">
            <h1 className="accomodation_title">Mon logement</h1>

            {isHomeDetailsLoading ? (
              <Skeleton count={1} height={40} borderRadius={15} />
            ) : (
              <div className="accomodation-list_container">
                {undefined !== allHomeDetails && allHomeDetails.length > 0 ? (
                  allHomeDetails.map((homeDetails) => (
                    <div key={homeDetails?.UGCode} className="accomodation-list_element">
                      <AccomodationList homeDetails={homeDetails} isLoading={isHomeDetailsLoading} />
                    </div>
                  ))
                ) : (
                  <div className="accomodation-list_empty">
                    <p>Aucun logement trouvé</p>
                  </div>
                )}
              </div>
            )}
          </section>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}

export default Accomodation
