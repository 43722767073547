import React from "react"
import Footer from "components/Footer"
import AuthLayout from "layouts/AuthLayout"
import { Link } from "react-router-dom"
import "./ErreurConso.scss"

export default function ErreurConso() {
  return (
    <AuthLayout>
      <section className="erreur-conso-container">
        <p>La connexion à vos compteurs vers ISTA / OCEA n’a pas pu aboutir.</p>
        <p>Merci de réessayer ultérieurement.</p>
        <p>Si ce problème persiste, contactez notre service client.</p>
        <Link to="/" replace className="backlink" title="Retourner à la page d'accueil">
          Page d&apos;accueil
        </Link>
      </section>
      <Footer />
    </AuthLayout>
  )
}
