import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import KRGlue from "@lyracom/embedded-form-glue"
import { useHistory } from "react-router-dom"

import "./paymentForm.scss"
import AuthLayout from "layouts/AuthLayout"
import Footer from "components/Footer"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { useAccount } from "../hooks/user"
import { createPayment } from "api/payzen"

function PaymentForm(props: any) {
  const dark = useSelector((state: RootState) => state.theme.dark)
  const history = useHistory()
  // eslint-disable-next-line react/destructuring-assignment
  const formToken = props.history.location.state?.formToken
  // eslint-disable-next-line react/destructuring-assignment
  const rentAmount = props.history.location.state?.rentAmount
  const endpoint = "https://static.payzen.eu/"
  const { data: user } = useAccount()
  const [expiredForm, setExpiredForm] = useState(false)
  const [closedForm, setClosedForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const loadPayzen = async (publicKey: string) => {
    KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
      .then(
        async ({ KR }) =>
          await KR.setFormConfig({
            /* set the minimal configuration */
            "kr-public-key": publicKey,
            formToken,
            "kr-language": "fr-FR",
            "kr-clear-on-error": true,
            "kr-spa-mode": true,
            "kr-hide-debug-toolbar": true,
          })
      )
      .then(
        async ({ KR }) =>
          await KR.onSubmit((resp: any) => {
            if (resp.clientAnswer.orderStatus === "PAID") {
              const state = {
                clientAnswer: resp.clientAnswer,
              }
              history.replace("/payzen-validation", state)
            }
            return false
          })
      )
      .then(
        async ({ KR }) =>
          await KR.onLoaded(() => {
            setExpiredForm(false)
            setIsLoading(false)
          })
      )
      .then(async ({ KR }) => await KR.addForm("kr-smart-form"))
      .then(async ({ KR, result }) => await KR.showForm(result.formId))
      .then(({ KR }) => {
        KR.onError((event: any) => {
          if (event.errorCode === "PSP_108" || event.errorCode === "PSP_099") {
            setExpiredForm(true)
            setTimeout(() => {
              history.replace("/loyer")
            }, 3000)
          }
          // Plus de tentative de paiement possible
          else if(event.orderCycle === "CLOSED") {
            setClosedForm(true)
            createPayment(rentAmount)
              .then((response) => {
                const data = JSON.parse(response.data)
                const { formToken: tok } = data.answer
                history.replace("/payzen-form", { formToken: tok, rentAmount })
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              })
          }
        })
      })
      .catch((error) => {
        console.error(`[Payzen]${error}`)
      })

    // @ts-ignore
    if (KRGlue.loaded) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (undefined !== user && undefined !== user.publicKey) {
      loadPayzen(user.publicKey)
    }
  }, [user])

  return (
    <>
      <Helmet>
        <title>Paiement - Espace Locataire - Vilogia</title>
      </Helmet>
      <AuthLayout>
        <h2 className={`payment-title ${dark ? "" : "light"}`}>Votre paiement par carte bancaire :</h2>
        <div className="payment-body" id="kr-payment-body">
          <div className="kr-smart-form" />
          {isLoading && <p>Veuillez patienter</p>}
          {expiredForm && <div className="customerror">Vous allez être redirigé automatiquement...</div>}
          {closedForm && <div className="customerror">Le paiement a été refusé. La page va être rechargée.</div>}
        </div>
        <button
          type="button"
          role="link"
          className="back-link"
          onClick={() => {
            history.replace("/dashboard")
          }}
        >
          Retour à votre Espace Personnel
        </button>
        <Footer />
      </AuthLayout>
    </>
  )
}

export default PaymentForm
