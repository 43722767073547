export interface IntroInfosActions {
  type: string
  data: any
}

export function setIntroInfosClose(bool: any): IntroInfosActions {
  return {
    type: "USER_INTRO_CLOSE",
    data: bool,
  }
}
