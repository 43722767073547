import { IonContent, IonPage } from "@ionic/react"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import PDFViewer from "../components/PDFViewer"

function PDF() {
  const router = useHistory<{
    filename: string

    dlName: string
  }>()

  const filename = router?.location?.state?.filename
  const dlName = router?.location?.state?.dlName

  useEffect(() => {
    if (!filename) {
      // Cry and redirect
      router.push("/")
    }
  }, [])

  return (
    <IonPage>
      <IonContent>
        <PDFViewer filename={filename} dlName={dlName} />
      </IonContent>
    </IonPage>
  )
}

export default PDF
