import { type FaqType } from "../types/common"
import { API } from "./axioshelper"

export async function fetchFAQList() {
  return await API.request<FaqType[]>({
    method: "get",
    url: "faq",
  }).then((response) => response.data)
}

export default fetchFAQList
