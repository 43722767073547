export interface NotificationsActions {
  type: string
  show: boolean
  waitingNotifIds: number[]
}

export function toggleNotification(): { type: string } {
  return {
    type: "TOGGLE_NOTIF",
  }
}

export function hideNotification(): NotificationsActions {
  return {
    type: "HIDE_NOTIF",
    show: false,
    waitingNotifIds: [],
  }
}

export function addWaitingNotifId(id: number): NotificationsActions {
  return {
    type: "ADD_WAITING_NOTIF",
    show: false,
    waitingNotifIds: [id],
  }
}

export function removeWaitingNotifId(id: number): NotificationsActions {
  return {
    type: "REMOVE_WAITING_NOTIF",
    show: false,
    waitingNotifIds: [id],
  }
}
