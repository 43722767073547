import { IonIcon } from "@ionic/react"
import React from "react"

import moment from "moment"
import "moment/locale/fr"

import "./RentHistory.scss"
import Skeleton from "react-loading-skeleton"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { useConfig } from "hooks/config"
import calendar from "../theme/images/grey_calendar.svg"
import { type RentHistoryType } from "../types/common"
import { useWindowSize } from "../utils/hooks"
import "react-loading-skeleton/dist/skeleton.css"

interface RentHistoryProps {
  history?: RentHistoryType[]
  isLoading: boolean
}

const defaultProps = {
  history: undefined,
}

function RentHistory({ history, isLoading }: RentHistoryProps) {
  const { data: config } = useConfig()
  const dark = useSelector((state: RootState) => state.theme.dark)

  const size = useWindowSize()

  return (
    <div className="rent_history-container card">
      <table className="rent_history-table">
        <caption className="sr-only">
          Historique de votre relevé détaillé, identifié par la date et le type de document, suivit du montant versé ou
          prélevé.
        </caption>
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Opération</th>
            <th scope="col">Montant</th>
          </tr>
        </thead>
        <tbody>
          {history ? (
            history?.map((value: any) => (
              <tr key={value.id}>
                <td className="date_payment">
                  {size.width > 768 && <IonIcon icon={calendar} aria-hidden="true" />}
                  {moment(value.date).locale("fr").format("L")}
                </td>
                <td className="">{value.libelle}</td>
                {value.montant > 0 ? (
                  <td className="positive_payment">+{parseFloat(value.montant.toFixed(2)).toLocaleString()} € </td>
                ) : (
                  <td
                    style={dark || !config ? undefined : { color: config.secondaryColor }}
                    className="negative_payment"
                  >
                    {parseFloat(value.montant.toFixed(2)).toLocaleString()} €{" "}
                  </td>
                )}
              </tr>
            ))
          ) : isLoading ? (
            <tr>
              <td>
                <Skeleton count={10} height={20} className="skeleton_loader--table" />
              </td>
              <td>
                <Skeleton count={10} height={20} className="skeleton_loader--table" />
              </td>
              <td>
                <Skeleton count={10} height={20} className="skeleton_loader--table" />
              </td>
            </tr>
          ) : (
            "Il n'y a rien à afficher pour le moment."
          )}
        </tbody>
      </table>
    </div>
  )
}

RentHistory.defaultProps = defaultProps

export default RentHistory
