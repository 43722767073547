import React, { type ReactNode } from "react"
import "./container.scss"

interface ContainerProps {
  children: ReactNode
  footer?: boolean
  input?: boolean
  signup?: boolean
  create?: boolean
  connect?: boolean
  desktopImage?: boolean
  dashboard?: boolean
  dashChildren?: boolean
  disconnect?: boolean
}

const defaultProps = {
  footer: false,
  input: false,
  signup: false,
  create: false,
  connect: false,
  desktopImage: false,
  dashboard: false,
  dashChildren: false,
  disconnect: false,
}

function Container({
  children,
  footer,
  input,
  signup,
  create,
  connect,
  desktopImage,
  dashboard,
  dashChildren,
  disconnect,
}: ContainerProps) {
  let classname = "container-default"

  if (footer) {
    classname += " container-default--footer"
  }

  if (input) {
    classname += " container-default--input"
  }

  if (signup) {
    classname += " container-default--signup"
  }

  if (create) {
    classname += " container-default--create"
  }

  if (connect) {
    classname += " container-default--connect"
  }

  if (desktopImage) {
    classname += " container-default--desktopImage"
  }

  if (dashboard) {
    classname += " container-default--dashboard"
  }

  if (dashChildren) {
    classname += " container-default--dashChildren"
  }

  if (disconnect) {
    classname += " container-default--disconnect"
  }

  return (
    <div className={classname}>
      <div>{children}</div>
    </div>
  )
}

Container.defaultProps = defaultProps

export default Container
