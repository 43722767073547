import { API } from "./axioshelper"

export async function createPayment(rentAmount: string | number | undefined) {
  return await API.request({
    method: "post",
    url: "payment/create",
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    data: {
      rentAmount,
    },
  })
}

export async function validatePayment(props: any) {
  return await API.request({
    method: "post",
    url: "payment/validatePayment",
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    data: {
      props,
    },
  })
}

export async function getPaymentSettings() {
  return await API.request({
    method: "get",
    url: "payment/settings",
  })
}
