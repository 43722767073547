import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { fetchAccount, fetchAddress } from "../api/account"
import { Address, Consent, BillingSummary, User, UserContract } from "../types/common"
import { fetchBillingSummary, fetchConsent } from "../api/profile"
import { fetchContractInfos } from "../api/userContract"

export function useAccount(): UseQueryResult<User> {
  return useQuery({
    queryKey: ["user_account"],
    queryFn: async () => fetchAccount(),
  })
}

export function useBillingSummary(): UseQueryResult<BillingSummary> {
  return useQuery({
    queryKey: ["profile"],
    queryFn: async () => fetchBillingSummary(),
  })
}

export function useProfileAddress(): UseQueryResult<Address> {
  return useQuery({
    queryKey: ["address"],
    queryFn: async () => fetchAddress(),
  })
}

export function useProfileConsent(): UseQueryResult<Consent[]> {
  return useQuery({
    queryKey: ["consent"],
    queryFn: async () => fetchConsent(),
    select: (data) => {
      const items = data.map((prod): any => ({
        ...prod,
      }))
      return items
    },
  })
}

export function useContractInfos(): UseQueryResult<UserContract> {
  return useQuery({
    queryKey: ["contract"],
    queryFn: async () => fetchContractInfos(),
  })
}

export default useAccount
