import * as Sentry from "@sentry/capacitor"
import { createBrowserHistory } from "history"
import { Route } from "react-router-dom"
import { BrowserTracing, reactRouterV5Instrumentation, withSentryRouting, init as ReactInit } from "@sentry/react"

export const sentryHistory = createBrowserHistory()
export const SentryRoute = withSentryRouting(Route)

export function initSentry(): void {
  Sentry.init(
    {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          // tracePropagationTargets: ["insitaction.org", /^\//],
          tracePropagationTargets: [
            "insitaction",
            "insitaction.org",
            "vilogia",
            /^https:\/\/admin\.espace-locataire-preprod\.vilogia\.fr/,
            /^\//,
          ],
          // @ts-ignore
          routingInstrumentation: reactRouterV5Instrumentation(sentryHistory),
        }),
      ],
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      sendClientReports: true,
      sampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLERATE),
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACESSAMPLERATE),
      attachStacktrace: true,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      dist: process.env.REACT_APP_SENTRY_DIST,
      enableTracing: true,
    },
    ReactInit
  )
}
