import { AuthAction } from "../actions/authActions"

interface Auth {
  access_token?: string
  refresh_token?: string
  remember_me: boolean
  expire_date: number
}

const auth: Auth = {
  access_token: undefined,
  refresh_token: undefined,
  expire_date: 0,
  remember_me: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const AuthReducer = (state: Auth = auth, action: AuthAction): Auth => {
  switch (action.type) {
    case "AUTH_SET_DATAS":
      return {
        ...state,
        ...action.data,
      }

    case "AUTH_UNSET_DATAS":
      return auth

    default:
      return state
  }
}

export default AuthReducer
