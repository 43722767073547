export interface ThemeActions {
  type: string
  data: boolean
}

export function setLightTheme(): ThemeActions {
  return {
    type: "SET_THEME",
    data: false,
  }
}

export function setDarkTheme(): ThemeActions {
  return {
    type: "SET_THEME",
    data: true,
  }
}

export function setIsSliderOpen(): ThemeActions {
  return {
    type: "SET_SLIDER",
    data: true,
  }
}

export function setIsSliderClose(): ThemeActions {
  return {
    type: "SET_SLIDER",
    data: false,
  }
}

export function setIsSliderIntroInfosOpen(): ThemeActions {
  return {
    type: "SET_SLIDER_INTRO_INFOS",
    data: true,
  }
}

export function setIsSliderIntroInfosClose(): ThemeActions {
  return {
    type: "SET_SLIDER_INTRO_INFOS",
    data: false,
  }
}
