import { API } from "./axioshelper"
import { Agency, Manager } from "../types/common"

export async function fetchAgencyInfos() {
  return await API.request<Agency>({
    method: "get",
    url: "contact/agency",
  }).then((response) => response.data)
}

export async function fetchAccountManager() {
  return await API.request<Manager>({
    method: "get",
    url: "contact/manager",
  }).then((response) => response.data)
}
