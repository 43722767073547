import { API } from "./axioshelper"

export async function patchSlider() {
  return await API.request({
    method: "patch",
    url: "account/lookedAtSlider",
  })
}

export default patchSlider
