import React, { type ReactNode } from "react"
import "./Button.scss"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { useConfig } from "hooks/config"

interface ButtonProps {
  children: ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: "button" | "submit" | "reset"
  className?: string
  disabled?: boolean
  href?: string
  value?: string
  buttonTitle?: string
}

const defaultProps = {
  onClick: undefined,
  type: "button",
  className: "",
  disabled: false,
  href: undefined,
  value: "",
  buttonTitle: "",
}

function Button({ children, className, href, type, value, disabled, buttonTitle, ...rest }: ButtonProps) {
  const dark = useSelector((state: RootState) => state.theme.dark)
  const { data: config } = useConfig()
  const router = useHistory()
  let computedClassName = "base-button green"

  if (className) {
    computedClassName += ` ${className}`
  }

  let style
  if (config) {
    const backgroundColor = computedClassName.includes("dark") ? config.secondaryColor : config.primaryColor
    style = dark ? undefined : { backgroundColor }
  }

  const goTo = (e: any) => {
    e.preventDefault()
    if (href) {
      router.push(href)
    }
  }

  if (href) {
    return (
      <a href={href} onClick={goTo} className={`base-button--a ${className}`} type="link">
        <div role="button" tabIndex={0} aria-pressed="false" className="base-button green" style={style}>
          {children}
        </div>
      </a>
    )
  }

  return (
    <>
      <button
        className={computedClassName}
        style={style}
        /* eslint-disable-next-line react/button-has-type */
        type={type}
        disabled={disabled}
        {...rest}
        title={buttonTitle}
        aria-label={buttonTitle}
      >
        {children}
      </button>
      {type === "submit" && (
        <input type="submit" className="hidden_button" style={style} value={value} disabled={disabled} />
      )}
    </>
  )
}

Button.defaultProps = defaultProps

export default Button
