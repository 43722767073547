export interface NewsActions {
  type: string
  data: any
}

export function setModalNoNews(bool: any): NewsActions {
  return {
    type: "SHOW_MODALE_NO_NEWS",
    data: bool,
  }
}
