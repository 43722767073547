import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { AsideInfosType, Document } from "../types/common"
import { fetchAsideInfos, fetchDocuments } from "../api/documents"

export function useAsideInfo(): UseQueryResult<AsideInfosType> {
  return useQuery({
    queryKey: ["asideInfo"],
    queryFn: async () => fetchAsideInfos(),
  })
}

export function useDocuments(category: null | string, year: null | string): UseQueryResult<Document[]> {
  return useQuery({
    queryKey: ["documents", { category, year }],
    queryFn: async () => fetchDocuments(category, year),
  })
}

export default useAsideInfo
