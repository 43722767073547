import { useEffect } from "react"
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { sentryHistory } from "Sentry"

function AppUrlListener() {
  const history = sentryHistory
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split(".fr").pop()
      if (slug) {
        history.push(slug)
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })
  }, [])

  return null
}

export default AppUrlListener
