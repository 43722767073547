import React, { useEffect, useState } from "react"
import { useAuth } from "utils/hooks"

import "./Legals.scss"
import AuthLayout from "layouts/AuthLayout"
import { IonContent, IonPage } from "@ionic/react"
import BuildLayout from "layouts/BuildLayout"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import ReturnButton from "components/ReturnButton"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { fetchAccessibility } from "../../api/legalNotice"

function Accessibility() {
  const [accessibility, setAccessibility] = useState<any>()
  const auth = useAuth()
  const { pathname } = useLocation()

  useEffect(() => {
    fetchAccessibility()
      .then((res) => {
        setAccessibility(res.data.acc)
      })
      .catch((err) => {
        console.error(err)
      })
    return () => {
      setAccessibility({})
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Accessibilité - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="La déclaration d’accessibilité est le résultat d’une évaluation effective de la conformité du service de communication au public en ligne à la norme de référence."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      {auth.access_token ? (
        <AuthLayout>
          <div className="main__container--legals">
            <div className="container-section-width--padded container-section-width--padded__legals ">
              <section className="legals__container">
                <ReturnButton />
                <h1 className="legals__title">Déclaration d’accessibilité</h1>
                <p dangerouslySetInnerHTML={{ __html: accessibility }} />
              </section>
            </div>
            <Footer />
          </div>
        </AuthLayout>
      ) : (
        <IonPage>
          <IonContent>
            <Header />
            <BuildLayout>
              <div className="container-section-width--padded container-section-width--padded__legals ">
                <section className="legals__container">
                  <ReturnButton />
                  <h1 className="legals__title">Déclaration d’accessibilité</h1>
                  <p dangerouslySetInnerHTML={{ __html: accessibility }} />
                </section>
              </div>
            </BuildLayout>
            <Footer />
          </IonContent>
        </IonPage>
      )}
    </>
  )
}

export default Accessibility
