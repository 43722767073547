const introInfos: { lookedAtInfosIntro: boolean } = {
  lookedAtInfosIntro: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const IntroInfosReducer = (state = introInfos, action: { type: string; data?: boolean | undefined }): any => {
  switch (action.type) {
    case "USER_INTRO_CLOSE":
      return {
        ...state,
        lookedAtInfosIntro: action.data,
      }

    default:
      return state
  }
}

export default IntroInfosReducer
