import React from "react"
import "./FaqPage.scss"
import { useLocation } from "react-router"
import "react-loading-skeleton/dist/skeleton.css"
import { Helmet } from "react-helmet-async"
import Faq from "components/Faq"
import Footer from "../../components/Footer"
import AuthLayout from "../../layouts/AuthLayout"
import { useFAQ } from "../../hooks/faq"
import LoadBar from "../../components/LoadBar"

function FaqPage() {
  const { pathname } = useLocation()

  const { data: faqData, isLoading } = useFAQ()

  return (
    <>
      <Helmet>
        <title>Questions fréquentes - Espace locataire - Vilogia</title>
        <meta name="description" content="Des réponses aux questions fréquentes." />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <section className="container-section-width--padded">
          <h1 className="accomodation_faq-title">Questions fréquentes</h1>
          <div className="faq_container_flex">
            {isLoading || undefined === faqData ? <LoadBar /> : <Faq faq={faqData} />}
          </div>
        </section>
        <Footer />
      </AuthLayout>
    </>
  )
}

export default FaqPage
