import { Address, type User } from "../types/common"
import { API } from "./axioshelper"

export async function fetchAccount() {
  return API.request<User>({
    method: "get",
    url: "account",
  }).then((response) => response.data)
}

export async function fetchAddress() {
  return await API.request<Address>({
    method: "get",
    url: "account/address",
  }).then((response) => response.data)
}

export async function checkAccount(societyCode: string, contract: string, birthdate: string) {
  return await API.request({
    method: "get",
    url: "account/check",
    params: {
      society: societyCode,
      contract,
      birthdate,
    },
  })
}

export async function createAccount(
  society: string,
  contract: string,
  birthdate: string,
  email: string,
  password: string
) {
  return await API.request({
    method: "post",
    url: "account/create",
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    data: {
      society,
      contract,
      birthdate,
      email,
      password,
    },
  })
}

export async function getRecoveryCode(email: string) {
  return await API.request({
    method: "post",
    url: "account/generate-recovery-code",
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    data: {
      email,
    },
  })
}

export default async function newPassword(recoveryCode: string, password: string) {
  return await API.request({
    method: "patch",
    url: "account/reset-password",
    data: {
      recoveryCode,
      password,
    },
  })
}

export async function resetEmail(newEmail: string) {
  return await API.request({
    method: "post",
    url: "account/reset-mail",
    data: {
      email: newEmail,
    },
  })
}

export async function resetEmailConfirm(validationCode: string) {
  return API.request({
    method: "post",
    url: "account/confirmation-mail",
    data: { token: validationCode },
  })
}

export async function validateAccount(validationCode: string) {
  return await API.request({
    method: "post",
    url: "account/validate",
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    data: {
      validationCode,
    },
  })
}

export async function deleteUserAccount() {
  return await new Promise((resolve, reject) => {
    API.request({
      method: "delete",
      url: "account/delete",
    }).then(
      (response) => {
        resolve(response)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export async function setOneSignalUuid(uuid: string) {
  return await API.request({
    method: "patch",
    url: `/account/uuid/${uuid}`,
  })
}
