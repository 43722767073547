import { useEffect } from "react"
import { IonIcon } from "@ionic/react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { type RootState } from "stores/reducers"
import locationSharp from "../theme/images/location-sharp.svg"
import eau from "../theme/images/EAU_COURANTE.jpeg"
import compteur from "../theme/images/COMPTEUR.jpeg"
import "./AccomodationList.scss"
import InsuranceStatus from "./InsuranceStatus"

function AccomodationList(props: { homeDetails: any; isLoading: boolean }) {
  const { homeDetails, isLoading } = props
  const location = useLocation()
  const history = useHistory()
  const dark = useSelector((state: RootState) => state.theme.dark)

  useEffect(() => {
    if (location.pathname === "/logement" && location.search) {
      if (location.search.includes("?errorCode")) {
        history.push("/erreur-consommation")
      }
    }
  }, [location.pathname])

  function getServiceDescription(serviceName: string) {
    switch (serviceName) {
      case "ISTA":
        return "Consulter mes relevés de compteur"
      case "OCEA":
        return "Consulter mes relevés de compteur d'eau"
      default:
        return "Papernest"
    }
  }

  function getServiceLogo(serviceName: string) {
    switch (serviceName) {
      case "ISTA":
        return compteur
      case "OCEA":
        return eau
      default:
        return "Papernest"
    }
  }

  return (
    <article className="container_accomodation-info card">
      <div className="container_accomodation-section">
        <section className="container_accomodation-title">
          <h2>{homeDetails?.kindLabel}</h2>
          <div>
            <p>Référence du bien</p>
            <p className="subtitle_UGCode">{homeDetails?.UGCode}</p>
          </div>
        </section>
        <div className="adress_container" title="Adresse">
          <IonIcon icon={locationSharp} aria-hidden="true" className="adress-icon" />
          <p>
            {homeDetails?.address?.street}
            <br />
            {homeDetails?.address?.zipcode} {homeDetails?.address?.city}
            <br />
            {homeDetails?.address?.complement}
          </p>
        </div>
        <InsuranceStatus homeDetails={homeDetails} isLoading={isLoading} />
      </div>
      <div className="container_accomodation-section accomodation_dark-background">
        {homeDetails?.floor && (
          <p>
            Étage
            <br />
            <span className="accomodation_value">{homeDetails?.floor}</span>
          </p>
        )}
        {homeDetails?.door?.trim() ? (
          <p>
            Porte
            <br />
            <span className="accomodation_value">{homeDetails.door.trim()}</span>
          </p>
        ) : null}
        {homeDetails?.typeLabel && (
          <p>
            Type
            <br />
            <span className="accomodation_value">{homeDetails.typeLabel}</span>
          </p>
        )}
        {homeDetails?.area && (
          <p>
            Surface
            <br />
            <span className="accomodation_value">{homeDetails.area.toLocaleString()} m&sup2;</span>
          </p>
        )}
        {homeDetails?.heatingMode && (
          <p>
            Chauffage
            <br />
            <span className="accomodation_value">{homeDetails.heatingMode}</span>
          </p>
        )}
        {homeDetails?.rentAmountHT && (
          <p>
            Loyer
            <br />
            <span className="accomodation_value">{homeDetails.rentAmountHT.toLocaleString()} €</span>
          </p>
        )}
        {homeDetails?.dpe && (
          <p>
            DPE
            <br />
            <span className="accomodation_value">{homeDetails.dpe}</span>
          </p>
        )}
        {homeDetails?.rentAmountFloor && (
          <p>
            Plafond de loyer
            <br />
            <span className="accomodation_value">{homeDetails.rentAmountFloor.toLocaleString()} €</span>
          </p>
        )}
      </div>
      {undefined !== homeDetails?.tenantService && homeDetails?.tenantService?.length > 0 && (
        <div className="container_accomodation-section services_container">
          {homeDetails?.kindCode === "LOG" && homeDetails.tenantService.length > 0 && (
            <>
              <h3 className="title_services">Vos consommations</h3>
              {homeDetails.tenantService.map((service: any) => (
                <>
                  {/* <TooltipIcon
                      help={service.name}
                      className={`help_services-${service.name.toLowerCase()}`}
                    /> */}
                  <a key={service.id} href={service.url} className={`list_service ${dark ? "dark" : ""}`}>
                    <span className="list_service_logo">
                      <img src={getServiceLogo(service.name)} alt="" />
                    </span>
                    {getServiceDescription(service.name)}
                  </a>
                </>
              ))}
            </>
          )}
        </div>
      )}
    </article>
  )
}

export default AccomodationList
