import React, { createRef } from "react"
import "./News.scss"

import { Link, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { connect } from "react-redux"
import ReturnButton from "components/ReturnButton"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import * as actions from "../../stores/actions/newsActions"
import Footer from "../../components/Footer"
import defaultNewsImage from "../../theme/images/default-news-image.png"
import AuthLayout from "../../layouts/AuthLayout"
import { useNews } from "../../hooks/news"


function ArticlesToDisplay({ contents }: any) {
  const refsArray = contents.map(() => createRef())

  return (
    <ul className="news_articles_container">
      {contents.map((content: any) => (
        <li key={content.id}>
          <Link
            to={{ pathname: `/actualites/${content.id}`, state: content }}
            aria-label={`Lien vers l'actualité ${content.title}`}
            title={`Lien vers l'actualité ${content.title}`}
            ref={refsArray[content.id]}
          >
            <div className="news_article_container">
              <img
                className="news_article_image"
                src={
                  content.visualWebPath ? `${process.env.REACT_APP_BACKEND}/${content.visualWebPath}` : defaultNewsImage
                }
                alt=""
              />
              <p className="news_article_title" dangerouslySetInnerHTML={{ __html: content.title }} />
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}

function News(props:any) {
  const {news, setModalNoNews} = props
  const { pathname } = useLocation()
  const { data: newsData, hasNextPage, fetchNextPage } = useNews()

  if (hasNextPage && newsData?.pages.length === 1) {
    fetchNextPage()
  }

  return (
    <>
      <Helmet>
        <title>Actualités - Espace locataire - Vilogia</title>
        <meta name="description" content="Ensemble des actualités en lien avec Vilogia." />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div>
          <section className="container-width--padded news-page">
            <ReturnButton url="dachboard" />
            <h1 className="news_title">En ce moment</h1>
            {undefined !== newsData && newsData.pages.map((data: any) => <ArticlesToDisplay contents={data} />)}

            {hasNextPage && (
              <button
                type="button"
                className="news_button-load"
                onClick={() => {
                  fetchNextPage()
                }}
                aria-label="Bouton pour afficher plus d'actualités"
              >
                Voir plus d&apos;actualités
              </button>
            )}
          </section>
        </div>
        <Modal isOpen={news.showModale} className="prompt_container" toggle={() => setModalNoNews(false)}>
          <ModalBody className="prompt_body">
            Cette actualité n&apos;existe plus
          </ModalBody>
          <ModalFooter className="prompt_footer">            
            <button
              type="button"
              className="news_button-load"
              onClick={() => {
                setModalNoNews(false)
              }}
              aria-label="Afficher la page"
            >
              Voir d&apos;autres actualités
            </button>
          </ModalFooter>
        </Modal>
        <Footer />
      </AuthLayout>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  news: state.news,
})

const mapDispatchToProps = (dispatch: any) => ({
  setModalNoNews: (bool: boolean) => {
    dispatch(actions.setModalNoNews(bool))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(News)
