import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { FaqType } from "../types/common"
import { fetchFAQList } from "../api/faq"

export function useFAQ(): UseQueryResult<FaqType[]> {
  return useQuery({
    queryKey: ["faq"],
    queryFn: async () => fetchFAQList(),
  })
}

export default useFAQ
