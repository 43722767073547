import { IonIcon } from "@ionic/react"
import React from "react"
import { useHistory } from "react-router"

import "./Rent.scss"
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import check from "../theme/images/checkmark-outline.svg"
import Button from "./Button"
import TooltipIcon from "./TooltipIcon"
import { type Billing as BillingType } from "../types/common"
import "react-loading-skeleton/dist/skeleton.css"

import "moment/min/locales"

interface BillingProps {
  billing?: BillingType
  isLoading: boolean
}

const defaultProps = {
  billing: undefined,
}

function Rent({ billing, isLoading }: BillingProps) {
  const router = useHistory()

  return (
    <div className="rent">
      <h1 className="rent__title">Mon loyer</h1>
      <div className="card rent__card">
        <div className="rent__total">
          <p>Montant total à payer*</p>
          {/* <IonIcon data-tip data-for="questionMarkRent" icon={questionMark} /> */}
          <TooltipIcon help="Montant total dû, hors règlements en cours. Certaines opérations peuvent prendre plusieurs jours." />
        </div>
        <span className="rent__price">
          {billing?.summary?.solde ? parseFloat(billing?.summary.solde.toFixed(2)).toLocaleString() : "0"}€
        </span>
      </div>
      {billing?.last_payment ? (
        <div className="rent__info">
          <IonIcon icon={check} aria-hidden="true" className="rent_info_icon" />
          <p>
            Votre dernier paiement a été effectué le{" "}
            <span className="strong">
              {moment(billing.last_payment.date).locale("fr").format("LL") || <Skeleton />}
            </span>
            , pour un montant de{" "}
            <span className="strong">
              {Math.abs(parseFloat(billing?.last_payment.amount.toFixed(2))).toLocaleString() || <Skeleton />}€
            </span>
          </p>
        </div>
      ) : isLoading ? (
        <Skeleton count={2} />
      ) : null}

      <Button
        className="rent__button dark-green"
        onClick={() => {
          router.push("/loyer")
        }}
        buttonTitle="Lien vers la page mon loyer"
      >
        Payer
      </Button>
    </div>
  )
}

Rent.defaultProps = defaultProps

export default Rent
