import { IonIcon } from "@ionic/react"
import React from "react"
import "./Message.scss"
import check from "../theme/images/checkmark-outline.svg"
import wrong from "../theme/images/wrong.svg"

interface MessageProps {
  text?: string
  type?: "success" | "error" | "info"
}

const defaultProps = {
  text: undefined,
  type: undefined,
}

function Message({ text, type = "success" }: MessageProps) {
  return (
    <div className={`block_message ${type}`}>
      <IonIcon icon={type === "success" ? check : wrong} className="message_icon" aria-hidden="true" />
      <p className="content_message red" role="alert" aria-live="assertive">
        {text}
      </p>
    </div>
  )
}

Message.defaultProps = defaultProps

export default Message
