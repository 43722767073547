import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from "@tanstack/react-query"
import {
  fetchRequestEnabled,
  fetchRequestQualifiers,
  fetchRequests,
  fetchRequestsDetails,
  getRequestId,
} from "../api/request"
import { DemandType, Qualifiers } from "../types/common"

export const REQUEST_API_LIMIT = 10 // defined in apibridge (nbElementsParPage)

export function useRequestEnabled(): any {
  return useQuery({
    queryKey: ["request_enabled"],
    queryFn: async () => fetchRequestEnabled(),
  })
}

export function useRequestQualifiers(): UseQueryResult<Qualifiers[]> {
  return useQuery({
    queryKey: ["request_qualifiers"],
    refetchOnWindowFocus: false,
    queryFn: async () => fetchRequestQualifiers(),
  })
}

export function useRequestId(): UseQueryResult<string> {
  return useQuery({
    queryKey: ["request_id"],
    refetchOnWindowFocus: false,
    queryFn: async () => getRequestId(),
  })
}

export function useRequests(): UseInfiniteQueryResult<InfiniteData<DemandType[]>> {
  return useInfiniteQuery({
    queryKey: ["requests"],
    queryFn: async ({ pageParam }) => fetchRequests(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) =>
      lastPage?.length === REQUEST_API_LIMIT ? allPages.length + 1 : undefined,
  })
}

export function useRequestDetails(idRequest: string): UseQueryResult<DemandType> {
  return useQuery({
    queryKey: ["request", { idRequest }],
    queryFn: async () => fetchRequestsDetails(idRequest),
  })
}

export default useRequestEnabled
