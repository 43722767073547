import { toast } from "react-toastify"
import { useHistory } from "react-router"
import { useDispatch } from "react-redux"
import { API } from "./axioshelper"
import { toggleErrorMessageAction } from "../stores/actions/toastMessageActions"
import { Home } from "../types/common"

export async function fetchHomeIds(isRequestPage: boolean = false) {
  return await API.request<number[]>({
    method: "get",
    url: "myHome",
  })
    .then((response) => response.data)
    .catch((error) => {
      if (!isRequestPage) {
        return
      }
      if (error?.response) {
        console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
        toast.error(error.response.data.error)
      } else {
        useDispatch()<any>(toggleErrorMessageAction())
      }
      useHistory().push("/demandes")
    })
}

export async function fetchHomeInfos(propertyId: string) {
  return await API.request<Home>({
    method: "get",
    url: `myHome/${propertyId}`,
  }).then((response) => response.data)
}

export default fetchHomeIds
