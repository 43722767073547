import { IonItem, IonIcon, IonSelect, IonSelectOption, isPlatform } from "@ionic/react"
import React, { useRef, useState } from "react"
import "./Input.scss"
import "./Select.scss"
import type { InputChangeEventDetail, SelectChangeEventDetail } from "@ionic/core/dist/types/interface"
import { handleKeyUp } from "utils/functions"
import check from "../theme/images/checkmark-outline.svg"
import wrong from "../theme/images/wrong.svg"
import { type FormikType } from "../types/utils"
import caretDown from "../theme/images/caret-down.svg"

interface InputProps {
  id: string
  name: string
  disabled?: boolean
  className?: string
  placeholder?: string
  value?: string | number | null
  icon?: any
  options: Array<{ value: any; label: string }>
  error?: string
  valid?: boolean
  onChange?: (event: CustomEvent<InputChangeEventDetail>) => void
  onBlur?: {
    (e: React.FocusEvent<any>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  formik?: FormikType<any>
  ariaLabelledby?: string
  ariaRequired?: boolean
  ariaDescId?: string
}

const defaultProps = {
  disabled: false,
  className: "",
  placeholder: "",
  value: null,
  icon: undefined,
  error: undefined,
  valid: false,
  onChange: undefined,
  onBlur: undefined,
  formik: undefined,
  ariaLabelledby: "",
  ariaRequired: false,
  ariaDescId: "",
}

function Select({
  id,
  name,
  placeholder,
  disabled,
  className = "",
  icon,
  value,
  onChange,
  onBlur,
  options,
  error,
  valid,
  formik,
  ariaLabelledby,
  ariaRequired,
  ariaDescId,
}: InputProps) {
  const ref = useRef<HTMLIonSelectElement>(null)

  const computedClassName = `input-wrapper ${className}`

  const [touched, setTouched] = useState(false)

  const handleChange = (e: CustomEvent<SelectChangeEventDetail<any>>) => {
    formik?.handleChange(e)
    setTouched(true)
  }

  // If formik is defined
  // Check if input is "touched", if yes display its error, if not don't display anything
  // Use error props if formik is undefined
  const computedError = formik ? (touched ? formik.errors[name] : undefined) : error

  // If formik is defined
  // Check if input is "touched" and there is no error
  // Use valid props if formik is undefined
  const computedValid = formik ? formik.touched[name] && !formik.errors[name] : valid

  const clickSelect = () => {
    if (ref) {
      ref.current?.open()
    }
  }

  return (
    <>
      <div className={computedClassName}>
        <label className="input-row">
          {icon && (
            <div className="side-item small">
              <IonIcon icon={icon} className="input_icon" aria-hidden="true" title={placeholder} />
            </div>
          )}
          <IonItem className="input">
            <IonSelect
              ref={ref}
              id={id}
              name={name}
              interface={isPlatform("mobile") ? "action-sheet" : "alert"}
              cancelText="Retour"
              placeholder={placeholder}
              disabled={disabled}
              value={formik ? formik.values[name] : value}
              onIonChange={onChange || handleChange}
              onIonBlur={() => (formik ? formik.handleBlur : onBlur)}
              onIonCancel={() => {
                setTouched(true)
              }}
              aria-labelledby={ariaLabelledby}
              aria-required={ariaRequired}
              aria-describedby={ariaDescId}
              onKeyUp={(e: any) => {
                handleKeyUp(e)
              }}
            >
              {options.map((option) => (
                <IonSelectOption value={option.value} key={option.value}>
                  {option.label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </label>
        {computedValid && (
          <div className="side side--no-border">
            <div className="side-item">
              <IonIcon icon={check} aria-hidden="true" />
            </div>
          </div>
        )}

        {computedError && (
          <div className="side side--no-border">
            <div className="side-item side-item_wrong">
              <IonIcon icon={wrong} aria-hidden="true" />
            </div>
          </div>
        )}
        <div className="side">
          <div // replace with button
            // Ionic select can't have id...
            onClick={clickSelect}
            title={placeholder}
            className="side-item side-item--no-focus"
          >
            <IonIcon title={placeholder} icon={caretDown} aria-hidden="true" />
          </div>
        </div>
      </div>
      {computedError && (
        <p className="input-error" id={ariaDescId}>
          {computedError as any}
        </p>
      )}
    </>
  )
}

Select.defaultProps = defaultProps

export default Select
