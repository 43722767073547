import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from "@tanstack/react-query"
import { fetchNew, fetchNews } from "../api/new"
import { New } from "../types/common"

export const NEWS_API_LIMIT = 3 // defined in BO (nbElementsParPage)

export function useNews(): UseInfiniteQueryResult<InfiniteData<New[]>> {
  return useInfiniteQuery({
    queryKey: ["news"],
    queryFn: async ({ pageParam = 0 }) => fetchNews(pageParam),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => (lastPage?.length === NEWS_API_LIMIT ? allPages.length : undefined),
  })
}

export function useNewsDetails(id: number): UseQueryResult<New> {
  return useQuery({
    queryKey: ["news", { id }],
    queryFn: async () => fetchNew(id),
  })
}

export default useNews
