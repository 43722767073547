import { combineReducers } from "@reduxjs/toolkit"
import { AuthReducer } from "./authReducer"
import { NotificationsReducer } from "./notificationsReducer"
import { PromptReducer } from "./promptReducer"
import { ToastReducer } from "./toastMessageReducer"
import { IntroInfosReducer } from "./introInfosReducer"
import ThemeReducer from "./themeReducer"
import { NewsReducer } from "./newsReducer"

const rootReducer: any = combineReducers({
  auth: AuthReducer,
  notif: NotificationsReducer,
  prompt: PromptReducer,
  toast: ToastReducer,
  theme: ThemeReducer,
  introInfos: IntroInfosReducer,
  news: NewsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
