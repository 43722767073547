import React, { useEffect, useState } from "react"
import { useAuth } from "utils/hooks"

import "./Legals.scss"
import AuthLayout from "layouts/AuthLayout"
import { IonContent, IonPage } from "@ionic/react"
import BuildLayout from "layouts/BuildLayout"
import ReturnButton from "components/ReturnButton"
import { useLocation } from "react-router"
import { Helmet } from "react-helmet-async"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { fetchCgu } from "../../api/legalNotice"

function CGU() {
  const [cgu, setcgu] = useState<any>()
  const auth = useAuth()
  const { pathname } = useLocation()

  useEffect(() => {
    fetchCgu()
      .then((res) => {
        setcgu(res.data.cgu)
      })
      .catch((err) => {
        console.error(err)
      })
    return () => {
      setcgu({})
    }
  }, [])

  return auth.access_token ? (
    <>
      <Helmet>
        <title>Conditions générales d&apos;utilisation - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="La déclaration d’accessibilité est le résultat d’une évaluation effective de la conformité du service de communication au public en ligne à la norme de référence."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="main__container--legals">
          <div className="container-section-width--padded container-section-width--padded__legals ">
            <section className="legals__container">
              <ReturnButton />
              <h1 className="legals__title">Conditions générales d&apos;utilisation</h1>
              <p dangerouslySetInnerHTML={{ __html: cgu }} />
            </section>
          </div>
          <Footer />
        </div>
      </AuthLayout>
    </>
  ) : (
    <>
      <Helmet>
        <title>Accessibilité - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="La déclaration d’accessibilité est le résultat d’une évaluation effective de la conformité du service de communication au public en ligne à la norme de référence."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage>
        <IonContent>
          <Header />
          <BuildLayout>
            <div className="container-section-width--padded container-section-width--padded__legals ">
              <section className="legals__container">
                <ReturnButton />
                <h1 className="legals__title">Conditions générales d&apos;utilisation</h1>
                <p dangerouslySetInnerHTML={{ __html: cgu }} />
              </section>
            </div>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default CGU
