import React from "react"
import { IonIcon } from "@ionic/react"
import { Helmet } from "react-helmet-async"
import "./Requests.scss"
import { useHistory, useLocation } from "react-router-dom"
import AuthLayout from "../../layouts/AuthLayout"
import Footer from "../../components/Footer"
import Button from "../../components/Button"
import iconHand from "../../theme/images/icon_hand.svg"
import iconMessage from "../../theme/images/icon_message.svg"
import "moment/min/locales"
import RequestsContent from "../../components/RequestsContent"

export default function Requests() {
  const router = useHistory()
  const { pathname } = useLocation()

  return (
    <>
      <Helmet>
        <title>Mes demandes - Espace Locataire - Vilogia</title>
        <meta name="description" content="Liste des demandes effectuées par l'utilisateur auprès de Vilogia" />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="messenger_content">
          <section className="container-section-width--padded messenger_container">
            <div className="messenger_top_container">
              <div className="messenger_title-container">
                <IonIcon icon={iconMessage} aria-hidden="true" />
                <h1>Messagerie</h1>
              </div>
              <Button
                className="messenger_link_new-message"
                type="button"
                onClick={() => {
                  router.push("/nouvelle-demande")
                }}
                buttonTitle="Lien vers la création d'une nouvelle demande"
              >
                <IonIcon icon={iconHand} aria-hidden="true" />
                Nouveau message
              </Button>
            </div>
            <RequestsContent />
          </section>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}
