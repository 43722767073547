import { type Society } from "../types/common"
import { API } from "./axioshelper"

export async function fetchSocieties() {
  return await API.request<Society[]>({
    method: "get",
    url: "societies",
    params: {
      token: process.env.REACT_APP_ANONYMOUS_TOKEN,
    },
  })
}

export default fetchSocieties
