import React, { useState } from "react"
import { IonIcon } from "@ionic/react"
import "./RequestDetail.scss"
import AuthLayout from "layouts/AuthLayout"
import Footer from "components/Footer"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { DemandDetails } from "types/common"
import iconMessage from "../../theme/images/icon_message.svg"
import chevronBackOutline from "../../theme/images/chevron-back-outline.svg"
import iconCalendar from "../../theme/images/calendar-outline.svg"
import { useRequestDetails } from "../../hooks/request"
import RequestStateLabel from "../../components/RequestStateLabel"

export default function RequestDetail() {
  const router = useHistory()
  const [opened, setopened] = useState<number | null>(null)
  const { pathname } = useLocation()
  const dark = useSelector((state: RootState) => state.theme.dark)
  const { id }: any = useParams()
  const { data: requestDetails } = useRequestDetails(id)

  const sortDetails = (a: DemandDetails, b: DemandDetails): number => {
    const sort = new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    if (sort === 0) {
      return parseInt(a.stepRange, 10) - parseInt(b.stepRange, 10)
    }
    return sort
  }

  return (
    <>
      <Helmet>
        <title>
          Détails de la demande
          {id} - Espace locataire - Vilogia
        </title>
        <meta name="description" content="Page de détails d'une requête avec historique des intéractions " />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="requestDetail_content">
          <section className="container-section-width--padded requestDetail_container">
            <div className="requestDetail_title-container">
              <IonIcon icon={iconMessage} aria-hidden="true" />
              <h1>Messagerie</h1>
            </div>
            <div className="requestDetail-demand_container card">
              <div className="requestDetail_return-container">
                <button
                  type="button"
                  onClick={() => {
                    router.push("/demandes")
                  }}
                  className="requestDetail_return-button"
                  title="Retour"
                >
                  <IonIcon icon={chevronBackOutline} aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="requestDetail_table-head-wrapper">
                  <table className="requestDetail_table-head">
                    <caption className="sr-only">
                      Regroupe la totalité des échanges concernant une demande, avec la possiblité de continue
                      l&apos;échange ou de fermer la conversation.
                    </caption>
                    <thead>
                      <tr className={`requestDetail_table-head_title-category ${dark ? " dark" : ""}`}>
                        <th>
                          <div className="requestDetail_date-cell">
                            <span className="title-ref">Date</span>
                            {requestDetails?.updateDate && (
                              <p>
                                <IonIcon icon={iconCalendar} aria-hidden="true" />
                                le{" "}
                                {new Intl.DateTimeFormat("fr-FR", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(requestDetails?.updateDate))}
                              </p>
                            )}
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="title-ref">Source</span>
                            <p>{requestDetails?.origin}</p>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="title-ref">Référence</span>
                            <p>{requestDetails?.idRequest}</p>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="title-ref">Catégorie</span>
                            <p>{requestDetails?.categoryLabel}</p>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="title-ref">Type</span>
                            <p>{requestDetails?.typeLabel}</p>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="title-ref">Etat</span>
                            <p>
                              <RequestStateLabel label={requestDetails?.stateCode} />
                            </p>
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="requestDetail_container_messages-list">
                  <div className="demandeList_message-cell message_title">
                    <p className="message-title">Message</p>
                    <p>{requestDetails?.comment}</p>
                  </div>
                  {requestDetails?.details?.sort(sortDetails).map((requestDetail: any, i: number) => (
                    <div // replace with button
                      key={requestDetail.id}
                      className={
                        opened === i
                          ? "demandeList_message-cell demandeList_message-cell__open"
                          : "demandeList_message-cell"
                      }
                      onClick={() => {
                        setopened(i)
                      }}
                      tabIndex={0}
                    >
                      <span className="sr-only">Cliquer pour voir le message entier</span>
                      <p className="message-title">
                        Réponse du{" "}
                        {new Intl.DateTimeFormat("fr-FR", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(requestDetail.startDate))}
                      </p>

                      <p className="demandeList_message-cell_content semi-bold">{requestDetail.label}</p>
                      {requestDetail.file && (
                        <button
                          type="button"
                          className="requestDetail_button-file"
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                          title="Voir le PDF"
                        >
                          {requestDetail.file}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                {/* TODO: remove comment to access closing and answering request */}
                {/* {!writeMessage && (
                  <div>
                    <button
                      onClick={() => setwriteMessage(true)}
                      className="requestDetail_button  requestDetail_button--more-info"
                    >
                      Je veux apporter une information supplémentaire
                    </button>
                    <button
                      onClick={() => console.log("close demand")}
                      className="requestDetail_button requestDetail_button--solve-issue"
                    >
                      J'ai résolu mon problème
                    </button>
                  </div>
                )}

                {writeMessage && (
                  <div className="requestDetail_input-message">
                    <form onSubmit={formik.handleSubmit}>
                      <InputTextMessenger
                        formik={formik}
                        fileList={fileList}
                        deleteFile={deleteFile}
                        setfileList={setfileList}
                      />
                    </form>
                  </div>
                )} */}
              </div>
            </div>
            <Link to="/demandes" className="requestDetail_link-request">
              Retour aux messages
            </Link>
          </section>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}
