import { type PromptActionsData } from "../reducers/promptReducer"

export interface PromptAction {
  type: string
  data: PromptActionsData | boolean
}

export function setCheckBlocking(data: boolean): PromptAction {
  return {
    type: "PROMPT_SET_CHECKBLOCKING",
    data,
  }
}

export function setPromptContent(data: PromptActionsData): PromptAction {
  return {
    type: "PROMPT_SET_CONTENT",
    data,
  }
}
