import { type Auth, type AuthParameters } from "../../types/common"

export interface AuthAction {
  type: string
  data?: AuthParameters
}

export function setAuthData(data: AuthParameters): AuthAction {
  return {
    type: "AUTH_SET_DATAS",
    data,
  }
}

export function setTokens(
  access_token: Auth["access_token"],
  refresh_token: Auth["refresh_token"],
  expire_date: Auth["expire_date"]
): AuthAction {
  return {
    type: "AUTH_SET_DATAS",
    data: {
      access_token,
      refresh_token,
      expire_date,
    },
  }
}

export function unsetAuth(): { type: string } {
  return {
    type: "AUTH_UNSET_DATAS",
  }
}
