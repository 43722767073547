import React from "react"
import { IonIcon } from "@ionic/react"
import iconTimer from "../theme/images/time-outline.svg"

interface RequestStateLabelProps {
  label?: string
}

const defaultProps = {
  label: undefined,
}

export default function RequestStateLabel({ label }: RequestStateLabelProps) {
  switch (label) {
    case "Enregistré":
      return (
        <span className="request_ongoing">
          <IonIcon icon={iconTimer} aria-hidden="true" /> En cours
        </span>
      )
    case "Clos":
      return <span className="request_closed">Clos</span>
    default:
      return null
  }
}

RequestStateLabel.defaultProps = defaultProps
