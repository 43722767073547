import React from "react"
import "./SkipLinks.scss"
import { HashLink } from "react-router-hash-link"
import { type SkipLink } from "types/common"
import { handleKeyUp } from "../utils/functions"

interface SkipLinksProps {
  skipLinks: SkipLink[]
}

function SkipLinks({ skipLinks }: SkipLinksProps) {
  return (
    <ul className="skipLink">
      {skipLinks.map((link) => (
        <li key={link.id}>
          <HashLink
            smooth
            to={`#${link.linkName}`}
            onKeyUp={(e: any) => {
              handleKeyUp(e)
            }}
          >
            Accéder au {link.linkTitle}
          </HashLink>
        </li>
      ))}
    </ul>
  )
}

export default SkipLinks
