import React, { useRef, useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Keyboard, Pagination, type Swiper as SwiperInterface } from "swiper"
import "swiper/scss"
import "@ionic/react/css/ionic-swiper.css"
import "swiper/css/pagination"
import "swiper/css/keyboard"
import { Link, useHistory, useLocation } from "react-router-dom"
import { type New } from "../types/common"
import defaultNewsImage from "../theme/images/default-news-image.png"
import Button from "./Button"
import "./NewsDashboard.scss"

function NewArticle({ news }: any) {
  return (
    <li className="news__article">
      <Link
        to={{ pathname: `/actualites/${news.id}`, state: news }}
        aria-label={`Article ${news.title}`}
        className="news__article_link"
      >
        <img
          className="news__article__image"
          src={news.visualWebPath ? `${process.env.REACT_APP_BACKEND}/${news.visualWebPath}` : defaultNewsImage}
          alt=""
          aria-hidden="true"
        />
        <p
          className="news__article__title"
          // dangerouslySetInnerHTML={{ __html: news.description }}
        >
          {news.title}
        </p>
      </Link>
    </li>
  )
}

interface NewsTypes {
  newsProps?: New[]
}

const defaultProps = {
  newsProps: [],
}

function NewsDashboard({ newsProps }: NewsTypes) {
  const router = useHistory()
  const slidesRef: any = useRef()
  const isPathnameLong = useLocation().pathname.split("/").length >= 3
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>()

  useEffect(() => {
    swiperInstance?.update()
    // slidesRef.current?.update();
  }, [])

  return (
    <div className="news">
      {isPathnameLong ? null : <h2 className="news__title">En ce moment</h2>}
      <ul className="news__articles">{newsProps?.map((news: any) => <NewArticle news={news} key={news.id} />)}</ul>
      <div className="news__articles--slider">
        <Swiper
          onSwiper={(swiper) => {
            setSwiperInstance(swiper)
          }}
          modules={[Pagination, Keyboard]}
          keyboard
          pagination
          // pager={true}
          // options={{ slidesPerView: "auto" }}
          ref={slidesRef}
          className="container__slider--slides"
        >
          {newsProps?.map((news: New) => (
            <SwiperSlide key={news.id}>
              <NewArticle news={news} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Button
        className="small news__button"
        onClick={() => {
          router.push("/actualites")
        }}
        buttonTitle="Lien vers la page actualités"
      >
        Voir toutes les actualités
      </Button>
    </div>
  )
}

NewsDashboard.defaultProps = defaultProps

export default NewsDashboard
