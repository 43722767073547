import { IonPage, IonContent, IonText } from "@ionic/react"
import React from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router"

import "./MailConfirmation.scss"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

import "@ionic/react/css/core.css"
import Container from "../../layouts/container/Container"
import BuildLayout from "../../layouts/BuildLayout"

function MailConfirmation() {
  const { pathname } = useLocation()
  return (
    <>
      <Helmet>
        <title>Confirmation par mail - Espace Locataire - Vilogia</title>
        <meta name="description" content="Validation de l'envoi du mail de réinitialisation du mot de passe" />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage>
        <Header />
        <IonContent role="main">
          <BuildLayout>
            <Container signup>
              <IonText>
                <p className="mail-confirmation">
                  <span className="mail-confirmation">Dernière étape !</span>
                  <br />
                  Vous allez recevoir dans quelques minutes un e-mail de validation pour vous connecter à votre Espace
                  Locataire.
                </p>
              </IonText>
            </Container>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default MailConfirmation
