import React, { useCallback, useRef, useState } from "react"
import { IonPage, isPlatform } from "@ionic/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Keyboard, Pagination, type Swiper as SwiperInterface } from "swiper"
import "swiper/scss"
import "@ionic/react/css/ionic-swiper.css"
import "swiper/css/pagination"
import "swiper/css/keyboard"

import "./SliderIntro.scss"

import { useDispatch } from "react-redux"
import { setIsSliderClose } from "stores/actions/themeActions"
import { useEventListener } from "utils/hooks"
import FocusTrap from "focus-trap-react"
import { useQueryClient } from "@tanstack/react-query"
import slide1 from "../theme/images/slider/slide1.png"
import slide2 from "../theme/images/slider/slide2.png"
import slide3 from "../theme/images/slider/slide3.png"
import slide4 from "../theme/images/slider/slide4.png"
import slide5 from "../theme/images/slider/slide5.png"
import nextButton from "../theme/images/slider/nextButton.svg"
import closeSlider from "../theme/images/slider/close_slider.svg"
import { patchSlider } from "../api/sliderIntro"

export interface SlideProps {
  title: string
  text: string
  image: string
}

function Slide({ title, text, image }: SlideProps) {
  return (
    <div className="slide__intro">
      <img src={image} alt={`illustration pour : ${text}`} className="img__slider" />
      <div className="container__content">
        <h1 className="slide__title">{title}</h1>
        <p className="slide__text">{text}</p>
      </div>
    </div>
  )
}

function SliderIntro() {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>()
  const slideRef = useRef<any>(null)
  const [isEnd, setisEnd] = useState<boolean | undefined>(false)
  const [isStart, setisStart] = useState<boolean | undefined>(true)
  const [activeIndex, setactiveIndex] = useState<number>(0)
  const dispatch = useDispatch()

  // Eviter l'erreur : Your trap should include a tabbable element or a focusable container //
  // const [active, setActive] = React.useState(state.theme.sliderOpen);

  // const handleTrapDeactivate = React.useCallback(function () {
  //   setActive(false);
  //   dispatch(setIsSliderClose());
  //   handleCloseSlider();
  // }, []);
  const queryClient = useQueryClient()

  const handleCloseSlider = () => {
    patchSlider().then(() => queryClient.invalidateQueries({ queryKey: ["account"] }))
    dispatch(setIsSliderClose())
  }

  const handlePrevious = (e: React.MouseEvent) => {
    e.preventDefault()
    swiperInstance?.slidePrev()
  }

  const handleNext = (e: React.MouseEvent) => {
    e.preventDefault()
    swiperInstance?.slideNext()
  }

  const slideChanged = async () => {
    setisEnd(swiperInstance?.isEnd)
    setisStart(swiperInstance?.isBeginning)
    setactiveIndex(swiperInstance!.activeIndex)
  }

  // close tooltip on escape
  const handleClose = useCallback((e: any) => {
    if (e.key === "Escape") {
      dispatch(setIsSliderClose())
    }
  }, [])

  useEventListener("keydown", handleClose)

  const sliderData = [
    {
      id: "slide1",
      image: slide1,
      title: "Simple, pratique, sécurisé",
      text: "Je découvre mon Espace Locataire.",
    },
    {
      id: "slide2",
      image: slide2,
      title: "Mes paiements en ligne",
      text: "Je règle mon loyer et gère mes prélèvements facilement.",
    },
    {
      id: "slide3",
      image: slide3,
      title: "Tous mes documents au même endroit",
      text: "Je consulte mes avis d’échéance, mes avis de régularisation de charges… en quelques clics.",
    },
    {
      id: "slide4",
      image: slide4,
      title: "Ma messagerie toujours accessible",
      text: "J’échange avec un conseiller en toute simplicité sur l’évolution de mon contrat et les démarches liées à mon logement.",
    },
    {
      id: "slide5",
      image: slide5,
      title: "Sur ordinateur ou sur mobile",
      text: "Mon Espace Locataire est disponible partout, à tout moment !",
    },
  ]

  return (
    <IonPage className="slider__background">
      <FocusTrap
        active={isPlatform("mobileweb") || isPlatform("desktop")}
        // focusTrapOptions={{
        //   onDeactivate: handleTrapDeactivate,
        // }}
      >
        <div
          className="container__slider"
          role="dialog"
          aria-modal="true"
          aria-label="Présentation de mon Espace Locataire en 5 étapes"
        >
          <button type="button" className="skip__button" onClick={handleCloseSlider} tabIndex={0}>
            Passer
          </button>
          <Swiper
            onSlideChange={slideChanged}
            onSwiper={(swiper) => {
              setSwiperInstance(swiper)
            }}
            modules={[Pagination, Keyboard]}
            keyboard
            pagination
            // pager={true}
            // options={{ slidesPerView: "auto" }}
            ref={slideRef}
            className="container__slider--slides"
          >
            {sliderData.map((slide: SlideProps) => (
              <SwiperSlide key={slide.title} aria-hidden={sliderData[activeIndex] === slide ? "false" : "true"}>
                <Slide {...slide} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={isStart ? "slide__button-container__start" : "slide__button-container"}>
            {!isStart && (
              <button type="button" className="slide__previous--button" onClick={handlePrevious} aria-label="Précédent">
                <img src={nextButton} alt="" />
              </button>
            )}
            {isEnd ? (
              <button
                type="button"
                className="slide__next--button slide__next--button__check"
                onClick={handleCloseSlider}
                aria-label="Fermer la modale"
              >
                <img src={closeSlider} alt="" />
              </button>
            ) : (
              <button type="button" className="slide__next--button" onClick={handleNext} aria-label="Suivant">
                <img src={nextButton} alt="" />
              </button>
            )}
          </div>
        </div>
      </FocusTrap>
    </IonPage>
  )
}

export default SliderIntro
