import React from "react"
import "./Loader.scss"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"

function Loader() {
  const dark = useSelector((state: RootState) => state.theme.dark)
  return (
    <div className={`lds-ring ${dark ? "dark" : ""}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default Loader
