import { API } from "./axioshelper"
import { type UserContract } from "../types/common"

export async function fetchContractInfos() {
  return await API.request<UserContract>({
    method: "get",
    url: "contract",
  }).then((response) => response.data)
}

export async function patchContractInfos(contractId: string) {
  return await API.request({
    method: "patch",
    url: "contract",
    data: { contractId },
  })
}
