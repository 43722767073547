import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonText,
  IonItem,
  IonRouterLink,
  IonMenuButton,
  IonButton,
  isPlatform,
} from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"

import "./HeaderConnected.scss"
import "./Disconnect.scss"
import { Link, useLocation } from "react-router-dom"
import { type RootState } from "stores/reducers"
import { type SkipLink } from "types/common"
import { Keyboard } from "@capacitor/keyboard"
import { useConfig } from "hooks/config"
import { useQueryClient } from "@tanstack/react-query"
import iconMenu from "../theme/images/icon_menu.svg"
import Disconnect from "./Disconnect"

import powerOutline from "../theme/images/power-outline.svg"
import iconHand from "../theme/images/icon_hand.svg"
import iconMessage from "../theme/images/icon_message.svg"
import iconBell from "../theme/images/icon_bell.svg"

import { useWindowSize } from "../utils/hooks"

import { type MenuLink, navBar } from "./Menu"
import ModalContract from "./ModalContract"
import { hideNotification, toggleNotification } from "../stores/actions/notificationsActions"
import TooltipIcon from "./TooltipIcon"

import SkipLinks from "./SkipLinks"
import DarkToggle from "./DarkToggle"
import { useAccount, useContractInfos } from "../hooks/user"
import { useNotificationCount } from "../hooks/notifications"

interface CustomLinkType {
  item: MenuLink
}

const skipLinks: SkipLink[] = [
  { linkName: "menu", linkTitle: "menu", id: "menu1" },
  { linkName: "content", linkTitle: "contenu", id: "menu2" },
  { linkName: "footer", linkTitle: "bas de page", id: "menu3" },
]

function CustomLink({ item }: CustomLinkType) {
  const router = useHistory()
  const activeLink = router.location.pathname.startsWith(item.link)

  return (
    <li>
      <Link
        to={item.link}
        className={`button_nav-bar_desktop ${activeLink ? "active" : ""}`}
        aria-current={activeLink ? "true" : "false"}
      >
        {item.title}
      </Link>
    </li>
  )
}

function HeaderConnected() {
  const count = useNotificationCount().data ?? 0
  const router = useHistory()
  const { data: config } = useConfig()
  const { data: user } = useAccount()
  const location = useLocation()
  const size = useWindowSize()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const [disconnectionModal, setDisconnectionModal] = useState(false)
  const [contractModal, setcontractModal] = useState(false)
  const [keyboardOpened, setKeyboardOpened] = useState(false)
  const closeModal = () => {
    setDisconnectionModal(false)
  }
  const closeContractModal = () => {
    setcontractModal(false)
  }
  const showNotif = useSelector((state: RootState) => state.notif.show)
  const disabledLogoutBtn = useSelector((state: RootState) => state.prompt.checkBlocking)
  const dark = useSelector((state: RootState) => state.theme.dark)

  const goHome = (e: React.MouseEvent<HTMLIonRouterLinkElement>) => {
    if (router.location.pathname === "/dashboard") {
      e.preventDefault()
    }
  }

  if (isPlatform("capacitor")) {
    if (location.pathname === "/nouvelle-demande") {
      Keyboard.addListener("keyboardDidShow", () => {
        setKeyboardOpened(true)
      })

      Keyboard.addListener("keyboardDidHide", () => {
        setKeyboardOpened(false)
      })
    }
  }

  const { data: contractsList } = useContractInfos()

  useEffect(() => {
    if (size.width < 1135) dispatch(hideNotification())
  }, [size.width, dispatch])

  const toggleNotif = () => {
    dispatch(toggleNotification())

    if (showNotif) {
      queryClient.invalidateQueries({ queryKey: ["notification-count"] })
      queryClient.resetQueries({ queryKey: ["notification"] })
    }
  }

  return (
    <>
      {size.width > 1135 ? <SkipLinks skipLinks={skipLinks} /> : null}
      {contractModal && contractsList && <ModalContract onClose={closeContractModal} contractsList={contractsList} />}
      {disconnectionModal && <Disconnect close={closeModal} />}
      {/* Desktop Version */}
      {size.width > 1135 && (
        <header>
          <IonHeader className="header_connected-desktop">
            <IonToolbar className="toolbar_header toolbar_header-top">
              <div className="toolbar-content container-width--padded">
                <div className="logo_container">
                  <IonRouterLink routerLink="/" routerDirection="back" onClick={goHome}>
                    <img
                      className="header_logo"
                      src={`${
                        // Pour forcer le typage en string
                        process.env.REACT_APP_BACKEND
                      }${user?.society.logoFullPath}`}
                      alt={`Logo ${user?.society.name}, retour à la page d'accueil`}
                      sizes="50"
                    />
                  </IonRouterLink>

                  <IonItem className="espace-loc_text" lines="none">
                    <IonText>
                      Espace
                      <br />
                      <span className="span_loc">Locataire</span>
                    </IonText>
                  </IonItem>

                  <div className="logo_container--contract">
                    <p className="logo_container--contract_text">
                      Bonjour {user?.firstName} {user?.lastName}
                    </p>
                    <p className="logo_container--contract_text">
                      Compte client n°
                      {user?.contract}
                    </p>

                    {(contractsList?.length ?? 0) > 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          setcontractModal(true)
                        }}
                      >
                        Voir un autre compte client
                      </button>
                    )}
                  </div>
                </div>

                <DarkToggle />

                <div>
                  <div className="desktop_tab-nav">
                    <IonButton className={`nav_button ${dark ? "dark" : "light"}`} onClick={toggleNotif}>
                      <div className="flex_container">
                        <IonIcon icon={iconBell} aria-hidden="true" />
                        Notifications
                        {count !== 0 && (
                          <span
                            className="counter"
                            style={{ right: `calc(50% - ${10 + count.toString().length * 5}px` }}
                          >
                            {count}
                          </span>
                        )}
                      </div>
                    </IonButton>
                    <Link to="/demandes" className={`nav_button ${dark ? "dark" : "light"}`}>
                      <div className="flex_container">
                        <IonIcon icon={iconMessage} aria-hidden="true" />
                        Messagerie
                      </div>
                    </Link>

                    <Link
                      to="/nouvelle-demande"
                      className={`nav_button new_message_button ${dark ? "dark" : "light"}`}
                      style={dark || !config ? undefined : { backgroundColor: config.primaryColor }}
                    >
                      <div className="flex_container">
                        <IonIcon icon={iconHand} aria-hidden="true" />
                        Nouveau
                        <br />
                        message
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </IonToolbar>

            <div
              className="toolbar_header toolbar_header-bottom container-width--padded"
              onClick={
                showNotif
                  ? () => {
                      dispatch(hideNotification())
                    }
                  : () => {}
              }
            >
              <div className="toolbar-content">
                <nav className="bottom-nav_desktop" id="menu">
                  <ul className="ul_desktop-nav">
                    {navBar.map((item) => (
                      <CustomLink item={item} key={item.id} />
                    ))}
                  </ul>
                </nav>

                <button
                  type="button"
                  className="disconnect_link"
                  onClick={() => {
                    setDisconnectionModal(true)
                  }}
                  disabled={disabledLogoutBtn}
                >
                  {disabledLogoutBtn ? (
                    <TooltipIcon
                      help="Vos modifications sur la page profil n'ont pas été enregistrées."
                      className="disabledIcon"
                      noLogout
                    />
                  ) : (
                    <IonIcon icon={powerOutline} className="disconnect_icon" aria-hidden="true" />
                  )}
                  Déconnexion
                </button>
              </div>
            </div>
          </IonHeader>
        </header>
      )}

      {/* Mobile Version */}

      {size.width < 1135 && (
        <>
          <header>
            <IonHeader className="header_connected">
              <div className="container_logo">
                <IonRouterLink routerLink="/">
                  <img
                    className="header_logo"
                    src={`${
                      // Pour forcer le typage en string
                      process.env.REACT_APP_BACKEND
                    }${user?.society.logoFullPath}`}
                    alt={`Logo ${user?.society.name}, retour à la page d'accueil`}
                  />
                </IonRouterLink>

                <div className="espace-loc_text">
                  <IonText>
                    Espace
                    <br />
                    <span className="span_loc">Locataire</span>
                  </IonText>
                </div>
              </div>

              <div className="button_menu-icon">
                <IonMenuButton slot="end" aria-expanded="false" aria-controls="mobileMenu">
                  <IonIcon className="menu_icon" icon={iconMenu} aria-hidden="true" />
                </IonMenuButton>
              </div>
            </IonHeader>
          </header>

          <footer className={`mobile_tab-nav-container ${keyboardOpened ? "keyboard-open" : ""}`}>
            <div className="mobile_tab-nav">
              <Link
                to="/notifications"
                className={`nav_button ${dark ? "dark" : "light"}`}
                aria-label="Ouverture du récapitulatif des notifications"
              >
                <div className="flex_container">
                  <IonIcon icon={iconBell} aria-hidden="true" />
                  Notifications
                  {count !== 0 && (
                    <span
                      className="counter"
                      style={{ right: `calc(50% - ${10 + count.toString().length * 5}px` }}
                    >
                      {count}
                    </span>
                  )}
                </div>
              </Link>

              <Link
                to="/demandes"
                className={`nav_button ${dark ? "dark" : "light"}`}
                aria-label="Lien vers la page de liste des demandes"
              >
                <div className="flex_container">
                  <IonIcon icon={iconMessage} aria-hidden="true" />
                  Messagerie
                </div>
              </Link>

              <Link
                to="/nouvelle-demande"
                className={`nav_button new_message_button ${dark ? "dark" : "light"}`}
                style={dark || !config ? undefined : { backgroundColor: config.secondaryColor }}
                aria-label="Lien vers la page de création d'une nouvelle demande"
              >
                <div className="flex_container">
                  <IonIcon icon={iconHand} aria-hidden="true" />
                  Nouveau
                  <br />
                  message
                </div>
              </Link>
            </div>
          </footer>
        </>
      )}
    </>
  )
}

export default HeaderConnected
