import { IonMenu, IonContent, IonIcon, IonList } from "@ionic/react"
import React, { useRef, useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import { type RootState } from "stores/reducers"
import { useSelector } from "react-redux"
import { useEventListener } from "utils/hooks"
import closeOutline from "../theme/images/close-outline.svg"
import powerOutline from "../theme/images/power-outline.svg"
import Disconnect from "./Disconnect"

import { useWindowSize } from "../utils/hooks"
import "./Menu.scss"
import ModalContract from "./ModalContract"
import TooltipIcon from "./TooltipIcon"

import DarkToggle from "./DarkToggle"
import { useAccount, useContractInfos } from "../hooks/user"

export interface MenuLink {
  title: string
  link: string
  id: string
}

export const navBar: MenuLink[] = [
  {
    title: "Mon profil",
    link: "/me",
    id: "ml1",
  },
  {
    title: "Mon loyer",
    link: "/loyer",
    id: "ml2",
  },
  {
    title: "Mes documents",
    link: "/documents",
    id: "ml3",
  },
  {
    title: "Mon logement",
    link: "/logement",
    id: "ml4",
  },
  {
    title: "Mes contacts",
    link: "/contact",
    id: "ml5",
  },
  {
    title: "FAQ",
    link: "/faq",
    id: "ml6",
  },
]

export function Menu() {
  const size = useWindowSize()
  const router = useHistory()
  const menuRef: any = useRef()
  const contentRef: any = useRef()
  const closeButton: any = useRef(null)
  const disconnectBtn: any = useRef()

  const close = () => menuRef.current.close()

  const focusOnCloseBtn = (ref: any) => {
    if (ref.current !== null) ref.current.focus()
  }

  const trapFocus = useCallback((e: any) => {
    if (e.key === "Tab") {
      e.preventDefault()
      focusOnCloseBtn(closeButton)
    }
  }, [])

  const [disconnectionModal, setDisconnectionModal] = useState(false)

  const [contractModal, setcontractModal] = useState(false)
  const closeContractModal = () => {
    setcontractModal(false)
  }

  const { data: user } = useAccount()

  const closeModal = () => {
    setDisconnectionModal(false)
  }
  const openModal = () => {
    close()
    setDisconnectionModal(true)
  }

  const [landscape, setlandscape] = useState(false)

  const disabledLogoutBtn = useSelector((state: RootState) => state.prompt.checkBlocking)

  useEffect(() => {
    if (size.width > size.height) {
      setlandscape(true)
      return
    }
    setlandscape(false)
  }, [size])

  const contractsList = useContractInfos()

  // Close menu on escape
  const handleClose = useCallback((e: any) => {
    if (e.key === "Escape") {
      close()
    }
  }, [])

  useEventListener("keydown", handleClose)

  return (
    <>
      {disconnectionModal && <Disconnect close={closeModal} />}
      {contractModal && contractsList.data && (
        <ModalContract onClose={closeContractModal} contractsList={contractsList.data} />
      )}
      <IonMenu
        side="end"
        menuId="first"
        id="mobileMenu"
        contentId="router-outlet"
        disabled={size.width > 1135}
        ref={menuRef}
        onIonDidOpen={() => {
          focusOnCloseBtn(closeButton)
        }}
        swipeGesture
      >
        <IonContent className={`menu_container ${landscape ? "mobile_landscape" : ""}`} ref={contentRef}>
          <div className="close-menu_container">
            <button
              type="button"
              className="close-menu__button"
              onClick={close}
              title="Fermer le menu"
              tabIndex={1}
              ref={closeButton}
              aria-expanded="true"
            >
              <IonIcon className="close-menu_icon" icon={closeOutline} title="Fermer" aria-hidden="true" />
            </button>
          </div>

          <nav className="main-nav">
            <IonList className="menu__list">
              <ul>
                {navBar.map((item) => (
                  <li key={item.id}>
                    <a
                      className="link"
                      onClick={(e) => {
                        e.preventDefault()
                        router.push(item.link)
                        close()
                      }}
                      href={item.link}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </IonList>
            <DarkToggle />
            <div className="logo_container--contract__mobile">
              <p className="logo_container--contract_text__mobile">
                Bonjour {user?.firstName} {user?.lastName}
              </p>
              <p className="logo_container--contract_text__mobile">
                Compte client n°
                {user?.contract}
              </p>

              {(contractsList.data?.length ?? 0) > 1 && (
                <button
                  type="button"
                  onClick={() => {
                    setcontractModal(true)
                  }}
                >
                  Voir un autre compte client
                </button>
              )}
            </div>
            <button
              type="button"
              className="disconnect_link"
              onClick={openModal}
              disabled={disabledLogoutBtn}
              onKeyDown={(e) => {
                trapFocus(e)
              }}
              ref={disconnectBtn}
            >
              {disabledLogoutBtn ? (
                <TooltipIcon
                  help="Vos modifications sur la page profil n'ont pas été enregistrées."
                  className="disabledIcon"
                  noLogout
                />
              ) : (
                <IonIcon icon={powerOutline} className="disconnect_icon" aria-hidden="true" />
              )}
              Déconnexion
            </button>
          </nav>
        </IonContent>
      </IonMenu>
    </>
  )
}

export default Menu
