import React from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import AuthLayout from "../../layouts/AuthLayout"
import Footer from "../../components/Footer"
import "./RentPage.scss"
import RentHistory from "../../components/RentHistory"
import RentPayment from "../../components/RentPayment"
import { useRent } from "../../hooks/rent"

function RentPage() {
  const { pathname } = useLocation()

  const { data: rent, isLoading } = useRent()

  return (
    <>
      <Helmet>
        <title>Mon loyer - Espace Locataire - Vilogia</title>
        <meta
          name="description"
          content="C'est sur cette page, que vous retrouverez le système de paiement en ligne pour le loyer, par prélèvement automatique ou non."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="rent_content">
          <div className="container-section-width--padded">
            <h1 className="rent__title">Mon loyer</h1>

            <RentPayment isLoading={isLoading} summary={rent?.summary} last_payment={rent?.last_payment} />
            <h2 className="rent__title rent_title_details">Mon relevé détaillé</h2>

            <RentHistory history={rent?.history} isLoading={isLoading} />
          </div>
          <Footer />
        </div>
      </AuthLayout>
    </>
  )
}

export default RentPage
