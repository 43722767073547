import React, { useEffect } from "react"
import { IonIcon, IonSpinner } from "@ionic/react"
import "./NewRequest.scss"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import iconHand from "../../theme/images/icon_hand.svg"
import Footer from "../../components/Footer"
import AuthLayout from "../../layouts/AuthLayout"
import NewRequestForm from "../../components/NewRequestForm"
import { useHomeIds } from "../../hooks/myHome"
import { useRequestEnabled, useRequestId } from "../../hooks/request"
import { toggleErrorMessageAction } from "../../stores/actions/toastMessageActions"

function NewRequest() {
  const location = useLocation()
  const { data: requestIsDisabled, isLoading: requestEnabledIsLoading } = useRequestEnabled()
  const { data: requestBiens, isLoading: requestBiensIsLoading, isError: requestBiensIsError } = useHomeIds(true)
  const { data: requestId, isLoading: requestIdIsLoading, isError: requestIdIsError } = useRequestId()
  const isLoading = requestEnabledIsLoading && requestBiensIsLoading && requestIdIsLoading
  const dispatch = useDispatch()
  const router = useHistory()

  useEffect(() => {
    if (
      (requestIsDisabled && !requestEnabledIsLoading) ||
      (!requestIsDisabled && !isLoading && (requestBiensIsError || requestIdIsError))
    ) {
      dispatch<any>(toggleErrorMessageAction())
      router.push("/demandes")
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Nouvelle demande - Espace Locataire - Vilogia</title>
        <meta name="description" content="Création d'une nouvelle demande auprès de Vilogia" />
        <link rel="canonical" href={location?.pathname} />
      </Helmet>
      <AuthLayout>
        <div className="top-banner_message ">
          <div className="new-message_banner-content container-section-width--padded">
            <IonIcon icon={iconHand} aria-hidden="true" />
            <h1>Nouveau message</h1>
          </div>
        </div>

        {!requestIsDisabled && isLoading && (
          <div className="ionspinner-content">
            <IonSpinner color="dark" />
          </div>
        )}

        {!requestIsDisabled && !isLoading && undefined !== requestBiens && undefined !== requestId && (
          <NewRequestForm requestBiens={requestBiens.map(el => `${el}`)} requestId={requestId} />
        )}

        <Footer />
      </AuthLayout>
    </>
  )
}

export default NewRequest
