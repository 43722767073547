import { IonText } from "@ionic/react"
import React, { useCallback, useEffect, useRef } from "react"
import "./Disconnect.scss"
import FocusTrap from "focus-trap-react"
import { useAuth, useEventListener } from "utils/hooks"
import { deleteToken } from "api/logout"
import { logout } from "../api/helper"
import Container from "../layouts/container/Container"

interface DisconnectProps {
  close?: any
}

const defaultProps = {
  close: undefined,
}

function Disconnect({ close }: DisconnectProps) {
  const ref: any = useRef()
  const auth = useAuth()

  // Close modal contract on escape
  const handleClose = useCallback(
    (e: any) => {
      if (e.key === "Escape") {
        close()
      }
    },
    [close]
  )

  const handleLogout = async () => {
    const token = auth.access_token
    logout()
    try {
      await deleteToken(token)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    ref.current.focus()
  }, [])

  useEventListener("keydown", handleClose)

  return (
    <Container disconnect>
      <FocusTrap>
        <div>
          <IonText>
            <h1 className="disconnect_title">Souhaitez-vous quitter votre Espace Locataire ?</h1>
          </IonText>
          <div className="button-container_disconnect">
            <button
              type="button"
              ref={ref}
              className="disconnect_button disconnect"
              onClick={() => {
                handleLogout()
              }}
            >
              me déconnecter
            </button>
            <button type="button" className="disconnect_button return" onClick={close}>
              poursuivre ma navigation
            </button>
          </div>
        </div>
      </FocusTrap>
    </Container>
  )
}

Disconnect.defaultProps = defaultProps

export default Disconnect
