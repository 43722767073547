import { IonPage, IonContent, IonText } from "@ionic/react"
import React from "react"
import { useHistory, useLocation } from "react-router"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Helmet } from "react-helmet-async"
import { getRecoveryCode } from "../../api/account"

import "./LostPassword.scss"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import BuildLayout from "../../layouts/BuildLayout"
import Container from "../../layouts/container/Container"
import Input from "../../components/Input"

import "@ionic/react/css/core.css"
import message from "../../theme/images/icon_letter.svg"
import Button from "../../components/Button"

function LostPassword() {
  const router = useHistory()
  const { pathname } = useLocation()

  const validationSchema: any = Yup.object().shape({
    email: Yup.string()
      .email("Veuillez renseigner une adresse e-mail valide, en incluant le symbole '@'")
      .required("Ce champ est obligatoire"),
  })

  const formik: any = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async ({ email }) => {
      await getRecoveryCode(email)
        .then(() => {
          router.push("/lost-password-confirmation", formik.values.email)
        })
        .catch((err) => {
          console.error(err)
        })
    },
  })

  return (
    <>
      <Helmet>
        <title>Mot de passe oublié - Espace locataire - Vilogia</title>
        <meta name="description" content="Renseignement de l'e-mail où recevoir la réinitialisation du mot de passe" />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage>
        <Header />
        <IonContent role="main">
          <BuildLayout>
            <Container signup>
              <IonText>
                <h1 className="lost-password">Mot de passe oublié ?</h1>
              </IonText>
              <form className="lost-password_inputs" onSubmit={formik.handleSubmit}>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  icon={message}
                  placeholder="Votre adresse e-mail"
                  help="Il s'agit de l'adresse e-mail saisie lors de votre inscription."
                  formik={formik}
                  title="Votre adresse e-mail"
                  ariaDescId="errorLostPassEmail"
                  ariaRequired
                  autoComplete="email"
                />
                <div className="button-container">
                  <Button
                    type="submit"
                    // Pas de message d'erreur visible si on disabled le bouton
                    disabled={!formik.values.email || !formik.isValid || formik.isSubmitting}
                  >
                    Je réinitialise mon mot de passe
                  </Button>
                </div>
              </form>
            </Container>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default LostPassword
