import { IonContent, IonPage } from "@ionic/react"
import ReturnButton from "components/ReturnButton"
import AuthLayout from "layouts/AuthLayout"
import BuildLayout from "layouts/BuildLayout"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router"
import { useAuth } from "utils/hooks"
import { fetchPdc } from "../../api/legalNotice"
import Footer from "../../components/Footer"

import Header from "../../components/Header"
import "./Legals.scss"

function PDC() {
  const [pdc, setpdc] = useState<any>()
  const auth = useAuth()
  const { pathname } = useLocation()

  useEffect(() => {
    fetchPdc()
      .then((res) => {
        setpdc(res.data.pdc)
      })
      .catch((err) => {
        console.error(err)
      })
    return () => {
      setpdc({})
    }
  }, [])

  return auth.access_token ? (
    <>
      <Helmet>
        <title>Politique de confidentialité - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="La politique de confidentialité regroupe l'ensemble des points sur lesquels Vilogia s'engage pour protéger vos données."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="main__container--legals">
          <div className="container-section-width--padded container-section-width--padded__legals ">
            <section className="legals__container">
              <ReturnButton />
              <h1 className="legals__title">Politique de confidentialité</h1>
              <p dangerouslySetInnerHTML={{ __html: pdc }} />
            </section>
          </div>
          <Footer />
        </div>
      </AuthLayout>
    </>
  ) : (
    <>
      <Helmet>
        <title>Politique de confidentialité - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="La politique de confidentialité regroupe l'ensemble des points sur lesquels Vilogia s'engage pour protéger vos données."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage>
        <IonContent>
          <Header />
          <BuildLayout>
            <div className="container-section-width--padded container-section-width--padded__legals ">
              <section className="legals__container">
                <ReturnButton />
                <h1 className="legals__title">Politique de confidentialité</h1>
                <p dangerouslySetInnerHTML={{ __html: pdc }} />
              </section>
            </div>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default PDC
