import React from "react"
import { useAuth } from "utils/hooks"

import "./Sitemap.scss"
import "./Legals.scss"
import AuthLayout from "layouts/AuthLayout"
import { IonContent, IonPage } from "@ionic/react"
import BuildLayout from "layouts/BuildLayout"
import { Helmet } from "react-helmet-async"
import { Link, useLocation } from "react-router-dom"
import ReturnButton from "components/ReturnButton"
import { type SkipLink } from "types/common"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

const sitemapConnectedLink: SkipLink[] = [
  { linkTitle: "Tableau de bord", linkName: "/dashboard", id: "connect1" },
  { linkTitle: "Mes documents", linkName: "/documents", id: "connect2" },
  { linkTitle: "Mon loyer", linkName: "/loyer", id: "connect3" },
  { linkTitle: "Mon profil", linkName: "/me", id: "connect4" },
  { linkTitle: "Mon logement", linkName: "/logement", id: "connect5" },
  { linkTitle: "Mes contacts", linkName: "/contact", id: "connect6" },
  { linkTitle: "FAQ", linkName: "/faq", id: "connect7" },
  { linkTitle: "Mes demandes", linkName: "/demandes", id: "connect8" },
  { linkTitle: "Nouvelle demande", linkName: "/nouvelle-demande", id: "connect9" },
  { linkTitle: "Actualités", linkName: "/actualites", id: "connect10" },
  { linkTitle: "Politique de confidentialité", linkName: "/pdc", id: "connect12" },
  { linkTitle: "Conditions générales d'utilisation", linkName: "/cgu", id: "connect13" },
  { linkTitle: "Déclaration d’accessibilité", linkName: "/accessibility", id: "connect14" },
]
const sitemapNotConnectedLink: SkipLink[] = [
  { linkTitle: "Je crée mon Espace Locataire", linkName: "/signup", id: "nconnect1" },
  { linkTitle: "Je me connecte à mon Espace Locataire", linkName: "/login", id: "nconnect2" },
  { linkTitle: "Mot de passe oublié", linkName: "/lost-password", id: "nconnect3" },
  { linkTitle: "Politique de confidentialité", linkName: "/pdc", id: "nconnect4" },
  { linkTitle: "Conditions générales d'utilisation", linkName: "/cgu", id: "nconnect5" },
  { linkTitle: "Déclaration d’accessibilité", linkName: "/accessibility", id: "nconnect6" },
]

function Sitemap() {
  const auth = useAuth()
  const { pathname } = useLocation()

  return (
    <>
      <Helmet>
        <title>Plan du site - Espace Locataire - Vilogia</title>
        <meta
          name="description"
          content="Le plan présente l’arborescence du site. A partir de cette arborescence, l’ensemble des pages du site sont accessibles."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      {auth.access_token ? (
        <AuthLayout>
          <div className="main__container--legals">
            <div className="container-section-width--padded container-section-width--padded__legals ">
              <section className="legals__container sitemap">
                <ReturnButton />
                <h1 className="legals__title">Plan du site</h1>
                <ul>
                  {sitemapConnectedLink.map((link) => (
                    <li key={link.id}>
                      <Link to={`${link.linkName}`} title={`${link.linkTitle}`}>
                        {link.linkTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
            </div>
            <Footer />
          </div>
        </AuthLayout>
      ) : (
        <IonPage>
          <IonContent>
            <Header />
            <BuildLayout>
              <div className="container-section-width--padded container-section-width--padded__legals ">
                <section className="legals__container sitemap">
                  <ReturnButton />
                  <h1 className="legals__title">Plan du site</h1>
                  <ul>
                    {sitemapNotConnectedLink.map((link) => (
                      <li key={link.id}>
                        <Link to={`${link.linkName}`} title={`${link.linkTitle}`}>
                          {link.linkTitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            </BuildLayout>
            <Footer />
          </IonContent>
        </IonPage>
      )}
    </>
  )
}

export default Sitemap
