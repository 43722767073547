import { IonPage, IonContent, IonText } from "@ionic/react"
import React, { useEffect, useState, useRef } from "react"
import { useHistory, useLocation } from "react-router"
import { useFormik } from "formik"
import "./Signup.scss"

import * as Yup from "yup"
import { Helmet } from "react-helmet-async"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Container from "../../layouts/container/Container"
import Input from "../../components/Input"
import BuildLayout from "../../layouts/BuildLayout"

import "@ionic/react/css/core.css"
import iconLock from "../../theme/images/lock-closed-outline.svg"
import iconBirthday from "../../theme/images/icon_birthday.svg"
import businessOutline from "../../theme/images/business-outline.svg"
import Button from "../../components/Button"
import Select from "../../components/Select"
import { fetchSocieties } from "../../api/societies"
import { type Society } from "../../types/common"
import { checkAccount } from "../../api/account"
import Message from "../../components/Message"
import { useFormState } from "../../utils/hooks"

function Signup() {
  const router = useHistory()
  const { pathname } = useLocation()
  const dateInput = useRef(null)

  const [societiesLoading, setsocietiesLoading] = useState(true)

  const { state, setstate, message, setmessage } = useFormState()

  const [societies, setSocieties] = useState<Society[]>([])

  useEffect(() => {
    fetchSocieties()
      .then((res) => {
        setSocieties(res.data)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
      .finally(() => {
        setsocietiesLoading(false)
      })
  }, [state])

  const validationSchema = Yup.object().shape({
    societyCode: Yup.string().required("Ce champ est obligatoire."),
    accountNumber: Yup.string()
      .matches(/^\d+$/, "Ce champ ne doit comporter que des chiffres.")
      .required("Ce champ est obligatoire."),
    birthday: Yup.date().required("Ce champ est obligatoire."),
  })

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      societyCode: "",
      accountNumber: "",
      birthday: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ societyCode, accountNumber, birthday }) => {
      if (state !== "normal") {
        setstate("normal")
      }
      checkAccount(societyCode, accountNumber, birthday)
        .then((res) => {
          let isEmail = { here: false, current: "" }
          if (res.data.email) {
            isEmail = { here: true, current: res.data.email }
          }
          const signupFormValues = formik.values
          router.push("/signup-create", { signupFormValues, isEmail })
        })
        .catch((err) => {
          if (err.response?.status === 409) {
            router.push("/login", {
              email: err.response?.data.email,
              message: "Bonne nouvelle : votre compte existe déjà.",
            })
          } else {
            setstate("error")
            setmessage("Les informations saisies ne correspondent pas à un client Vilogia")
          }
        })
    },
  })

  const getSocietiesOptions = () => {
    const options: Array<{ label: string; value: string }> = []
    societies.forEach((society) => options.push({ label: society.name, value: society.code }))

    return options
  }

  return (
    <>
      <Helmet>
        <title>Création de compte, renseigner son contrat existant - Espace Locataire - Vilogia</title>
        <meta name="description" content="Première partie de la création de compte pour l'Espace Locataire Vilogia" />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage className="signup-page">
        <Header />
        <IonContent className="signup-page_content" role="main">
          <BuildLayout>
            <Container signup>
              <IonText>
                <h1>
                  <span className="create_title">Je crée</span>
                  <br />
                  mon Espace Locataire
                </h1>
              </IonText>
              {state === "success" ? <Message text={message} /> : null}
              {state === "error" ? <Message type="error" text={message} /> : null}
              <form className="input-signup" onSubmit={formik.handleSubmit}>
                <div id="societyLabelledBy">Société</div>
                <Select
                  id="societyCode"
                  name="societyCode"
                  className="input-society"
                  placeholder="Société"
                  icon={businessOutline}
                  options={getSocietiesOptions()}
                  disabled={societiesLoading}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  ariaRequired
                  ariaLabelledby="societyLabelledBy"
                />
                <div id="accountNumberLabelledBy">Numéro de compte client</div>
                <Input
                  id="accountNumber"
                  name="accountNumber"
                  className="input-contract"
                  icon={iconLock}
                  placeholder="Votre numéro de compte client"
                  help="Retrouvez votre numéro de compte client sur votre avis d’échéance."
                  formik={formik}
                  title="Numéro de compte client"
                  ariaLabelledby="accountNumberLabelledBy"
                  trim
                />
                <div id="birthdayLabelledBy">Date de naissance</div>
                <Input
                  className="input-birthday"
                  id="birthday"
                  name="birthday"
                  icon={iconBirthday}
                  placeholder="Votre date de naissance"
                  type="date"
                  formik={formik}
                  innerRef={dateInput}
                  title="Date de naissance"
                  ariaLabelledby="birthdayLabelledBy"
                  autoComplete="bday"
                />

                <div className="button-container">
                  <Button type="submit" disabled={!formik.isValid}>
                    Je crée
                  </Button>
                </div>
              </form>
            </Container>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default Signup
