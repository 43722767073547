import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import { Modal, ModalBody, ModalFooter } from "reactstrap"
import * as actions from "../stores/actions/promptActions"
import Button from "./Button"
import "./CustomPrompt.scss"

function CustomPrompt(props: any) {
  const history = useHistory()
  const { setCheckBlocking, prompt } = props
  const [showPrompt, setShowPrompt] = useState(false)
  const [pathname, setPathname] = useState("")

  useEffect(() => {
    history.block((location: { pathname: React.SetStateAction<string> }) => {
      if (prompt.checkBlocking) {
        setPathname(location.pathname)
        setShowPrompt(true)
        return false
      }
      return undefined
    })
  }, [props, history])

  const handleOK = useCallback(async () => {
    history.block(() => {})
    setCheckBlocking(false)
    history.push(pathname)
    setShowPrompt(false)
  }, [pathname, history, setCheckBlocking])

  const handleCancel = useCallback(async () => {
    setShowPrompt(false)
  }, [])
  return showPrompt ? (
    <Modal isOpen={showPrompt} className="prompt_container" toggle={handleCancel}>
      <ModalBody className="prompt_body">
        {prompt.text}
        <br />
        {prompt.subtext}
      </ModalBody>
      <ModalFooter className="prompt_footer">
        <Button onClick={prompt.onOk ? handleOK : handleCancel} className="prompt_button">
          {prompt.ok}
        </Button>
        <Button onClick={!prompt.onCancel ? handleCancel : handleOK} className="prompt_button cancel">
          {prompt.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null
}

const mapStateToProps = (state: any) => ({
  prompt: state.prompt,
})

const mapDispatchToProps = (dispatch: any) => ({
  setCheckBlocking: (bool: boolean) => {
    dispatch(actions.setCheckBlocking(bool))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomPrompt)
