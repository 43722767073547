interface ThemeState {
  dark?: boolean
  sliderOpen?: boolean
  sliderInfosOpen?: boolean
}

const theme: ThemeState = {
  dark: undefined,
  sliderOpen: false,
  sliderInfosOpen: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const ThemeReducer = (state: ThemeState = theme, action: any): ThemeState => {
  switch (action.type) {
    case "SET_THEME":
      return {
        ...state,
        dark: action.data,
      }

    case "SET_SLIDER":
      return {
        ...state,
        sliderOpen: action.data,
      }

    case "SET_SLIDER_INTRO_INFOS":
      return {
        ...state,
        sliderInfosOpen: action.data,
      }

    default:
      return state
  }
}

export default ThemeReducer
