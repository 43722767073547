import React, { useEffect, useState } from "react"
import { IonIcon, IonSpinner } from "@ionic/react"
import Button from "./Button"

import TooltipIcon from "./TooltipIcon"
import check from "../theme/images/checkmark-outline.svg"
import calendar from "../theme/images/calendar.png"
import { useMediaExist } from "../utils/hooks"
import { useBillingSummary } from "../hooks/user"
import { getSepaFile } from "../utils/functions"

function Prelevement() {
  const [clickState, setClickState] = useState<"initial" | "loading" | "success" | "error">("initial")
  const { data: billingSummary } = useBillingSummary()

  useEffect(() => {
    setClickState("initial")

    return () => {
      setClickState("initial")
    }
  }, [])

  const mediaExist: boolean = useMediaExist(billingSummary?.sepa)

  return (
    <div className="container_prelevement" title="Mes prélèvements">
      <section className="auto-payment_profile">
        <h2>Prélèvement</h2>
        <div className="auto-payment_profile-container card">
          {billingSummary?.samplingDate && (
            <div className="payment_deadline">
              <IonIcon icon={check} aria-hidden="true" />
              <p>
                Échéance le
                {billingSummary?.samplingDate}
              </p>
            </div>
          )}

          <div className="auto-payment_profile-btn-container">
            <Button
              disabled={!mediaExist || !billingSummary?.sepa}
              onClick={() => {
                if (undefined !== billingSummary) {
                  getSepaFile(billingSummary, setClickState)
                }
              }}
              type="button"
              buttonTitle="Télécharger un mandat SEPA"
              className={
                billingSummary?.samplingDate
                  ? "automatic-payment_button dark-green automatic-payment_button-profile"
                  : "automatic-payment_button dark-green automatic-payment_button-profile_alone "
              }
            >
              {clickState === "loading" ? (
                <IonSpinner />
              ) : (
                <>
                  <img src={calendar} alt="" className="calendar_icon" aria-hidden="true" />
                  <div>
                    <span className="auto-payment_span">TÉLÉCHARGER UN MANDAT SEPA</span>
                    <br />
                    PDF
                    <strong> {`${Math.round(billingSummary?.sepaSize || 0)} ko` || "Poids non défini"}</strong>
                  </div>
                </>
              )}
            </Button>
            <TooltipIcon help="Pour mettre en place le prélèvement automatique : imprimez le mandat SEPA, remplissez et scannez le document, puis renvoyez-le accompagné d'un RIB via la messagerie." />
          </div>
        </div>
      </section>
    </div>
  )
}

export default Prelevement
