import { API } from "./axioshelper"
import { NotificationDetails, Notification } from "../types/common"

export async function fetchNotifications(pagination: number) {
  return API.request<Notification[]>({
    method: "get",
    url: `notification?page=${pagination}`,
  }).then((response) => response)
}

export async function fetchNotification(id: number) {
  return API.request<NotificationDetails>({
    method: "get",
    url: `notification/${id}`,
  })
}

export async function notificationIsSeen(id: number) {
  return await API.request({
    method: "patch",
    url: `notification/${id}`,
  })
}

export async function notificationsAreSeen() {
  return await API.request({
    method: "patch",
    url: `notification`,
  })
}

export async function fetchNotificationCounter() {
  return API.request({
    method: "get",
    url: `notification/count`,
  })
}

export default fetchNotifications
