import { useQuery, UseQueryResult, useQueries } from "@tanstack/react-query"
import { fetchHomeIds, fetchHomeInfos } from "../api/myHome"
import { Home } from "../types/common"

export function useHomeIds(isRequestPage: boolean = false): UseQueryResult<string[]> {
  return useQuery({
    queryKey: ["myhome"],
    queryFn: async () => fetchHomeIds(isRequestPage),
  })
}

export function useHomeDetail(propertyId: number | string): UseQueryResult<Home> {
  return useQuery({
    queryKey: ["myhome", propertyId],
    queryFn: async () => fetchHomeInfos(`${propertyId}`),
  })
}

export function useHomeDetails(propertyIds: Array<string>): UseQueryResult<Home>[] {
  return useQueries({
    queries: propertyIds.map((propertyId) => ({
      queryKey: ["myhome", propertyId],
      queryFn: async () => fetchHomeInfos(propertyId),
    })),
    combine: (result) =>
      result
        .filter((data) => !data.isLoading && data)
        .sort((a, b) => {
          const aCode = a.data?.kindCode === "LOG" ? 0 : 1
          const bCode = b.data?.kindCode === "LOG" ? 0 : 1
          return aCode - bCode
        }),
  })
}

export function useAllHomeDetails() {
  const { data: homeIds } = useHomeIds()
  return useQueries({
    queries: homeIds
      ? homeIds.map((id) => ({
        queryKey: ["myhomeUtils", id],
        queryFn: async () => fetchHomeInfos(id),
      }))
      : [],
    combine: (result) => ({
      data: result.map((r) => r.data),
      isLoading: result.some((r) => r.isLoading),
      pending: result.some((r) => r.isPending),
    }),
  })
}

export default useHomeIds
