import { IonIcon } from "@ionic/react"
import React, { useState } from "react"

import chevronDownOutline from "../theme/images/chevron-down-outline.svg"
import chevronForwardOutline from "../theme/images/chevron-forward-outline.svg"
import "./Faq.scss"

import { type FaqType } from "../types/common"

function Question({ data }: any) {
  const [openQuestion, setopenQuestion] = useState(false)
  const [openContainer, setopenContainer] = useState(false)

  const handleOpening = () => {
    setopenContainer(!openContainer)
    setopenQuestion(!openQuestion)
  }

  return (
    <>
      <button
        type="button"
        className="button_faq"
        onClick={() => {
          handleOpening()
        }}
        aria-expanded={openQuestion ? "true" : "false"}
        aria-controls={`tab${data.id}`}
      >
        <h3>{data.question.title}</h3>
        {openQuestion ? (
          <IonIcon icon={chevronDownOutline} aria-hidden="true" />
        ) : (
          <IonIcon icon={chevronForwardOutline} aria-hidden="true" />
        )}
      </button>
      {openContainer && (
        <div id={`tab${data.id}`}>
          {data.question.groups.map((e: any) => (
            <div key={e.id} className="question_container">
              <h4 className="question_title" dangerouslySetInnerHTML={{ __html: e.question }} />
              <div className="question_text" dangerouslySetInnerHTML={{ __html: e.answer }} />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

function Faq({ faq }: { faq: FaqType[] }) {
  return (
    <div className="faq_container">
      {faq?.map((question, i) => (
        <div key={question.id}>
          <Question data={{ question, i }} />
        </div>
      ))}
    </div>
  )
}

export default Faq
