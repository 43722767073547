import { IonIcon } from "@ionic/react"
import React from "react"
import { useHistory, useLocation } from "react-router"
import Skeleton from "react-loading-skeleton"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import AuthLayout from "../../layouts/AuthLayout"
import Footer from "../../components/Footer"
import "./Contact.scss"
import timeOutline from "../../theme/images/time-outline.svg"
import personOutline from "../../theme/images/person-outline.svg"
import iconHand from "../../theme/images/icon_hand.svg"
import locationOutline from "../../theme/images/location-outline.svg"
import phoneIcon from "../../theme/images/call-outline.svg"
import calendarOutline from "../../theme/images/calendar-outline.svg"
import "react-loading-skeleton/dist/skeleton.css"
import { useAgencyInfos, useManagerInfos } from "../../hooks/contact"

function Contact() {
  const router = useHistory()
  const { pathname } = useLocation()
  const dark = useSelector((state: RootState) => state.theme.dark)

  const { data: agency, isLoading: agencyIsLoading } = useAgencyInfos()
  const { data: manager, isLoading: managerIsLoading } = useManagerInfos()
  
  console.log('agency:', agency)
  const openingHours = [
    {
      day: agency?.openingTime?.monday && "Lundi",
      hour: agency?.openingTime?.monday,
    },
    {
      day: agency?.openingTime?.tuesday && "Mardi",
      hour: agency?.openingTime?.tuesday,
    },
    {
      day: agency?.openingTime?.wednesday && "Mercredi",
      hour: agency?.openingTime?.wednesday,
    },
    {
      day: agency?.openingTime?.thursday && "Jeudi",
      hour: agency?.openingTime?.thursday,
    },
    {
      day: agency?.openingTime?.friday && "Vendredi",
      hour: agency?.openingTime?.friday,
    },
    {
      day: agency?.openingTime?.saturday && "Samedi",
      hour: agency?.openingTime?.saturday,
    },
  ]
  return (
    <>
      <Helmet>
        <title>Mes contacts - Espace locataire - Vilogia</title>
        <meta name="description" content="Accès aux contacts personnels chez Vilogia et aux horaires de votre agence" />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="section_contact">
          <div className="container-section-width--padded">
            <h1>Mes contacts</h1>
            <div className="contact-main-container">
              <div>
                <button
                  type="button"
                  className={`contact__button ${dark ? "dark" : ""}`}
                  onClick={(e) => {
                    e.preventDefault()
                    router.push("/nouvelle-demande")
                  }}
                >
                  {agencyIsLoading ? (
                    <Skeleton className="skeleton__loader--contact" baseColor="#bed400" />
                  ) : (
                    <>
                      <IonIcon icon={iconHand} aria-hidden="true" className={`${dark ? "dark" : ""}`} />
                      Nous contacter
                    </>
                  )}
                </button>
                <h2>
                  {(!agencyIsLoading && agency?.agencyLabel) || <Skeleton className="skeleton__loader--contact" />}
                </h2>
                <div className="adress_contact" title="Localisation">
                  <IonIcon icon={locationOutline} aria-hidden="true" />
                  <p>
                    <span className="sr-only">Adresse postale de votre agence :</span>
                    {(!agencyIsLoading && agency?.address?.street) || (
                      <Skeleton className="skeleton__loader--contact" />
                    )}
                    <br />
                    {(!agencyIsLoading && agency?.address?.zipcode) || (
                      <Skeleton className="skeleton__loader--contact" />
                    )}{" "}
                    {!agencyIsLoading && agency?.address ? (
                      agency?.address?.city
                    ) : (
                      <Skeleton className="skeleton__loader--contact" />
                    )}{" "}
                  </p>
                </div>
                <div className="opening_hour" title="Horaire d'ouverture">
                  <div className="opening_hour_container">
                    <IonIcon icon={timeOutline} aria-hidden="true" />
                    <table className="table_opening">
                      <caption className="sr-only">
                        Horaire d&apos;ouverture de votre agence de {agency?.address?.city} du lundi au samedi.
                      </caption>
                      {agencyIsLoading ? (
                        <Skeleton className="skeleton__loader--contact" />
                      ) : (
                        <>
                          <thead className="sr-only">
                            <tr>
                              <th scope="col">Jour de la semaine</th>
                              <th scope="col">Horaire d&apos;ouverture</th>
                            </tr>
                          </thead>
                          <tbody>
                            {openingHours[0].hour !== null
                              ? openingHours.map((openingHour) =>
                                openingHour.hour !== null ? (
                                  <tr key={openingHour.day}>
                                    <th scope="row" className="openingHourDay">
                                      {openingHour.day}
                                    </th>
                                    <td>{openingHour.hour}</td>
                                  </tr>
                                ) : null
                              )
                              : null}
                          </tbody>
                        </>
                      )}
                    </table>
                  </div>
                  {!agencyIsLoading && (
                    <>
                      {agency?.subTitleLabel ? <p style={{ marginTop: "15px", marginLeft: "26px" }}>{agency?.subTitleLabel}</p> : null}
                      {agency?.titleLabel ? <p style={{ marginTop: "5px", marginLeft: "26px" }}>{agency?.titleLabel}</p> : null}
                    </>
                  )}
                </div>
                <div className="phone-wrapper">
                  {agency?.links && agency.links[0]?.type?.toLowerCase() === "reservation" && (
                    <div className="phone-block rdv-btn">
                      <div className="phone-container">
                        <a
                          href={agency.links[0].url}
                          target="blank"
                          rel="noreferrer"
                          aria-label="Prendre un rendez-vous avec votre agence"
                          className="phone_button"
                        >
                          {(!agencyIsLoading && (
                            <>
                              <IonIcon icon={calendarOutline} aria-hidden="true" className="icon-white" />
                              Prendre un RDV
                            </>
                          )) || <Skeleton className="skeleton__loader--contact" baseColor="#bed400" />}
                        </a>
                      </div>
                    </div>
                  )}

                  {agency?.phone && (
                    <div className="phone-block phone-btn">
                      <div className="phone-container">
                        <a
                          href={`tel:+33${agency?.phone.substring(1)}`}
                          aria-label={`Appeler votre agence au ${agency?.phone.substring(1)}`}
                          className="phone_button"
                        >
                          {(!agencyIsLoading && (
                            <>
                              <IonIcon icon={phoneIcon} aria-hidden="true" className="icon-white" />
                              {agency?.phone}
                            </>
                          )) || <Skeleton className="skeleton__loader--contact" baseColor="#bed400" />}
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="phone-text order-2">
                    {!agencyIsLoading && agency?.links && agency?.links[0]?.type?.toLowerCase() === "reservation" && agency?.links[0].url && (
                      <span>
                        * Pour les rendez-vous, vous serez reçus par nos équipes à l&apos;accueil de l&apos;agence.
                      </span>
                    )}
                  </div>
                  <div className="phone-text order-4">
                    {!agencyIsLoading && (agency?.phoneMention || agency?.phoneRate) && (
                      <span>{agency?.phoneMention ? agency?.phoneMention : agency?.phoneRate}</span>
                    )}
                  </div>
                </div>
                {!managerIsLoading && undefined !== manager?.name && (
                  <div title="Votre conseiller">
                    <h2>Votre conseiller</h2>
                    <div className="advisor-content">
                      <IonIcon icon={personOutline} aria-hidden="true" />
                      <p>{manager.name}</p>
                    </div>
                  </div>
                )}
                {managerIsLoading && <Skeleton className="skeleton__loader--contact" />}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}

export default Contact
