import { useConfig } from "hooks/config"
import "./BuildLayout.scss"

function BuildLayout(props: { children: any }) {
  const { children } = props
  const { data: config } = useConfig()

  if (!config?.homeVisualPath) {
    return null
  }

  return (
    <div className="login">
      <div className="login_content" id="content">
        {children}
      </div>
      <img
        src={`${process.env.REACT_APP_BACKEND}/${config.homeVisualPath}`}
        className="desktop_image"
        alt=""
        aria-hidden="true"
      />
    </div>
  )
}

export default BuildLayout
