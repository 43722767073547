import React, { useEffect } from "react"
import { IonContent, IonPage, IonSpinner } from "@ionic/react"
import BuildLayout from "layouts/BuildLayout"
import ReturnButton from "components/ReturnButton"
import { useHistory } from "react-router"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { Spinner } from "reactstrap"
import { useQueryClient } from "@tanstack/react-query"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { impersonate } from "../api/misc"
import store from "../stores/store"
import { setAuthData } from "../stores/actions/authActions"

function Impersonation() {
  const { token }: any = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()

  useEffect(() => {
    impersonate(token)
      .then((res) => {
        store.dispatch(
          setAuthData({
            expire_date: res.data.expire_date,
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
            remember_me: true,
          })
        )

        queryClient.removeQueries()
        setTimeout(() => history.push("/dashboard"), 2000)
      })
      .catch(() => {
        history.push("/login")
      })
    return () => {}
  }, [])

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <IonPage>
        <IonContent>
          <Header />
          <BuildLayout>
            <div className="container-section-width--padded container-section-width--padded__legals ">
              <section className="legals__container">
                <ReturnButton />
                <h1 className="legals__title">Vérification des droits</h1>
                <div>
                  <IonSpinner />
                  <Spinner />
                </div>
              </section>
            </div>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default Impersonation
