import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Storage } from "@ionic/storage"
import { type RootState } from "../stores/reducers"
import { type FormState } from "../types/utils"
import { type Auth } from "../types/common"
import { testMedia } from "../api/test"
import { sentryHistory } from "../Sentry"

export const useAuth = (): Auth => useSelector((state: RootState) => state.auth)

export function useLocationValues() {
  return sentryHistory.location.pathname
}

export const useRequireAuth = (redirectUrl = "/login"): Auth => {
  const auth = useAuth()
  const router = useHistory()
  const saveAndRedirect = async () => {
    const store = new Storage()
    await store.create()
    const logOut = await store.get("loggedOut")

    if (!logOut) {
      await store.set("redirect", router.location.pathname)
    }

    await store.remove("loggedOut")
    router.replace(redirectUrl)
  }
  // If auth.access_token is undefined that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (!auth.access_token) {
      saveAndRedirect()
    }
  }, [auth, router])

  return auth
}

export const useRequireGuest = (redirectUrl = "/dashboard"): Auth => {
  const auth = useAuth()
  const router = useHistory()
  const destroyAndRedirect = async () => {
    const store = new Storage()
    await store.create()

    const path = await store.get("redirect")
    await store.remove("redirect")
    router.push(path || redirectUrl)
  }

  // If auth.access_token is undefined that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (auth.access_token) {
      destroyAndRedirect()
    }
  }, [auth, router])

  return auth
}

export const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return windowSize
}

export interface FormStateInterface {
  state: FormState
  setstate: React.Dispatch<React.SetStateAction<FormState>>
  message: string
  setmessage: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  setloading: React.Dispatch<React.SetStateAction<boolean>>
}

export const useFormState = (): FormStateInterface => {
  const [state, setstate] = useState<FormState>("normal")
  const [message, setmessage] = useState("")
  const [loading, setloading] = useState(false)

  return {
    state,
    setstate,
    message,
    setmessage,
    loading,
    setloading,
  }
}

export const useEventListener = (eventName: string, handler: any, element = window): void => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (element && element.addEventListener) {
      // @ts-expect-error
      const eventListener = (event: any) => savedHandler.current(event)

      element.addEventListener(eventName, eventListener)

      return (): void => {
        element.removeEventListener(eventName, eventListener)
      }
    }

    return undefined
  }, [eventName, element])
}

export const useMediaExist = (path?: string) => {
  const [mediaExist, setMediaExist] = useState<boolean>(false)

  useEffect(() => {
    if (undefined !== path) {
      testMedia(path)
        .then(() => {
          setMediaExist(true)
        })
        .catch(() => {
          setMediaExist(false)
        })
    }
  }, [path])

  return mediaExist
}
