import { IonContent, IonPage } from "@ionic/react"
import React from "react"

import "./fallback.scss"
import { useHistory } from "react-router"
import Button from "../components/Button"

import Header from "../components/Header"

export default function Fallback() {
  const router = useHistory()

  return (
    <IonPage>
      <Header />
      <IonContent>
        <div className="fallback">
          <span>404</span>
          <p>La page que vous recherchez est introuvable</p>
          <Button
            className="fallback_button"
            onClick={() => {
              router.push("/")
            }}
          >
            Revenir à l&apos;accueil
          </Button>
        </div>
      </IonContent>
    </IonPage>
  )
}
