import React from "react"
import "./LoadBar.scss"
import Skeleton from "react-loading-skeleton"

function LoadBar() {
  return (
    <div className="loader_container">
      <Skeleton className="skeleton__loader" />
    </div>
  )
}

export default LoadBar
