import { API } from "./axioshelper"

export async function testMedia(path: String) {
  return await new Promise((resolve, reject) => {
    API.request({
      method: "get",
      url: `resource-exist?file=${path}`,
    }).then(
      (response) => {
        resolve(response)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export default testMedia
