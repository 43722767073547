import React, { useCallback, useRef, useState } from "react"
import FocusTrap from "focus-trap-react"
import "./SliderInfosIntro.scss"

import { Swiper, SwiperSlide } from "swiper/react"
import { Keyboard, Pagination, type Swiper as SwiperInterface } from "swiper"
import "swiper/scss"
import "@ionic/react/css/ionic-swiper.css"
import "swiper/css/pagination"
import "swiper/css/keyboard"

import { useDispatch } from "react-redux"
import { setIsSliderIntroInfosClose } from "stores/actions/themeActions"
import { IonPage, isPlatform } from "@ionic/react"
import slide1 from "../theme/images/slider/slide1.png"
import slide2 from "../theme/images/slider/slide2.png"
import slide3 from "../theme/images/slider/slide3.png"
import nextButton from "../theme/images/slider/nextButton.svg"
import closeSlider from "../theme/images/slider/close_slider.svg"

import { setIntroInfosClose } from "../stores/actions/introInfosActions"

import { useEventListener } from "../utils/hooks"

interface SlideProps {
  title: string
  text: string
  text2?: string
  image: string
  list?: boolean
  listText?: string[]
}

const defaultProps = {
  text2: "",
  list: false,
  listText: [],
}

function Slide({ title, text, text2, image, list, listText }: SlideProps) {
  return (
    <div className="slide__intro">
      <img src={image} alt={`illustration pour : ${text}`} className="img__slider" />
      <div className="container__content">
        <h1 className="slide__title">{title}</h1>
        <p className="slide__text">{text}</p>
        {text2 && (
          <>
            <br />
            <p className="slide__text">{text2}</p>
          </>
        )}
        {list && <ul className="slide__list-container">{listText?.map((item) => <li key={item}>{item}</li>)}</ul>}
      </div>
    </div>
  )
}

Slide.defaultProps = defaultProps

function SliderInfosIntro() {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>()
  const slideRef = useRef<any>(null)
  const [isEnd, setisEnd] = useState<boolean | undefined>(false)
  const [isStart, setisStart] = useState<boolean | undefined>(true)
  const [activeIndex, setactiveIndex] = useState<number>(0)
  const dispatch = useDispatch()

  // Eviter l'erreur : Your trap should include a tabbable element or a focusable container //
  // const handleTrapDeactivate = React.useCallback(function () {
  //   // setActive(false);
  //   dispatch(setIsSliderIntroInfosClose());
  //   handleCloseSlider();
  // }, []);

  //

  const handleCloseSlider = () => {
    dispatch(setIntroInfosClose(true))
  }

  const handlePrevious = (e: React.MouseEvent) => {
    e.preventDefault()
    swiperInstance?.slidePrev()
  }

  const handleNext = (e: React.MouseEvent) => {
    e.preventDefault()
    swiperInstance?.slideNext()
  }

  const slideChanged = async () => {
    setisEnd(swiperInstance?.isEnd)
    setisStart(swiperInstance?.isBeginning)
    setactiveIndex(swiperInstance!.activeIndex)
  }

  // close tooltip on escape
  const handleClose = useCallback((e: any) => {
    if (e.key === "Escape") {
      dispatch(setIntroInfosClose(true))
      dispatch(setIsSliderIntroInfosClose())
    }
  }, [])

  useEventListener("keydown", handleClose)

  const sliderData = [
    {
      id: "slide1",
      image: slide1,
      title: "Votre Espace Locataire",
      text: "Prenez quelques minutes pour créer votre compte et profitez de toutes ses fonctionnalités !",
    },
    {
      id: "slide2",
      image: slide2,
      title: "Je crée mon compte",
      text: "3 informations sont nécessaires pour créer votre compte :",
      list: true,
      listText: [
        "la société",
        "votre numéro de compte client. Retrouvez les 6 chiffres sur votre avis d'échéance.",
        "votre date de naissance.",
      ],
    },
    {
      id: "slide3",
      image: slide3,
      title: "Je choisis mon mot de passe",
      text: "Renseignez une adresse mail et choisissez un mot de passe. Vous recevrez un courrier dans votre boîte électronique. En cliquant dans le mail, vous créez votre nouveau compte !",
      text2:
        "Et voilà, avec votre adresse mail et le mot de passe choisi, vous pouvez vous connecter quand vous le souhaitez à votre nouvel Espace Locataire.",
    },
  ]

  return (
    <IonPage className="slider__background">
      <FocusTrap
        active={isPlatform("mobileweb") || isPlatform("desktop")}
        // focusTrapOptions={{
        //   onDeactivate: handleTrapDeactivate,
        // }}
      >
        <div
          className="container__slider"
          role="dialog"
          aria-modal="true"
          aria-label="Présentation de mon Espace Locataire en 5 étapes"
        >
          <button type="button" className="skip__button" onClick={handleCloseSlider} tabIndex={0}>
            Passer
          </button>
          <Swiper
            onSlideChange={slideChanged}
            onSwiper={(swiper) => {
              setSwiperInstance(swiper)
            }}
            modules={[Pagination, Keyboard]}
            keyboard
            pagination
            // pager={true}
            // options={{ slidesPerView: "auto" }}
            ref={slideRef}
            className="container__slider--slides"
          >
            {sliderData.map((slide) => (
              <SwiperSlide key={slide.id} aria-hidden={sliderData[activeIndex] === slide ? "false" : "true"}>
                <Slide {...slide} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={isStart ? "slide__button-container__start" : "slide__button-container"}>
            {!isStart && (
              <button type="button" className="slide__previous--button" onClick={handlePrevious} aria-label="Précédent">
                <img src={nextButton} alt="" />
              </button>
            )}
            {isEnd ? (
              <button
                type="button"
                className="slide__next--button slide__next--button__check"
                onClick={handleCloseSlider}
                aria-label="Fermer la modale"
              >
                <img src={closeSlider} alt="" />
              </button>
            ) : (
              <button type="button" className="slide__next--button" onClick={handleNext} aria-label="Suivant">
                <img src={nextButton} alt="" />
              </button>
            )}
          </div>
        </div>
      </FocusTrap>
    </IonPage>
  )
}

export default SliderInfosIntro
