import { IonButton, IonIcon, NavContext } from "@ionic/react"
import React, { useCallback, useContext } from "react"
import { Link } from "react-router-dom"
import "./NotificationsMini.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEventListener } from "utils/hooks"
import { type RootState } from "stores/reducers"
// import { useInView } from "react-intersection-observer"
import { useQueryClient } from "@tanstack/react-query"
import { hideNotification } from "../stores/actions/notificationsActions"
import chevronForwardOutline from "../theme/images/chevron-forward-outline.svg"
import { NOTIF_API_LIMIT, useNotification, useNotificationCount, useNotifications } from "../hooks/notifications"
import { notificationIsSeen, notificationsAreSeen } from "../api/notification"

import iconDocument from "../theme/images/notification/document.svg"
import iconMessage from "../theme/images/notification/message.svg"
import iconEuro from "../theme/images/notification/euro.svg"
import iconWarning from "../theme/images/notification/warning.svg"
import iconNews from "../theme/images/notification/news.svg"

interface NotificationDetailType {
  id: number
  itemProps: Object
  refetch: any
  pageIndex: number
}

const NOTIF_SIZE = 70

function NotificationDetail({ id, itemProps }: NotificationDetailType) {
  const dispatch = useDispatch()
  const dark = useSelector((state: RootState) => state.theme.dark)
  const { data, isLoading } = useNotification(id)
  const queryClient = useQueryClient()
  const { navigate } = useContext(NavContext)

  const {slug, icon, message, externalUrl, urlType} = data || {slug: "", icon: "", message: "", urlType: "SLUG"}
  const to = (urlType === "EXTERNAL" ? externalUrl : slug) || "/dashboard";

  const toggleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    notificationIsSeen(id).then(() => {
      dispatch(hideNotification())
      queryClient.invalidateQueries({ queryKey: ["notification-count"] })
      queryClient.resetQueries({ queryKey: ["notification"] })
      if(urlType === "SLUG" || to === "/dashboard") {
        navigate(to)
      } else {
        window.open(to, '_blank');
      }
    })
  }

  const getIcon = (name?: string) => {
    switch (name) {
      case "document":
        return iconDocument
      case "euro":
        return iconEuro
      case "message":
        return iconMessage
      case "news":
        return iconNews
      case "warning":
        return iconWarning
      default:
        return iconMessage
    }
  }

  return (
    <Link
      {...itemProps}
      title={`Accédez à ${to?.toLowerCase()} `}
      to={{pathname: to}}
      className={`notif_link ${dark ? "dark" : ""} ${isLoading ? "notif-animated-background" : ""}`}
      key={id}
      onClick={toggleOpen}
      style={{ height: `auto` }}
      target={urlType === "EXTERNAL" ? "_blank": undefined}
    >
      <div className="notif_item_wrapper">
        <IonIcon className="notif_icon" icon={getIcon(icon)} aria-hidden="true" />
        <div className="notif_text">{message}</div>
        <IonIcon className="notif_chevron" icon={chevronForwardOutline} aria-hidden="true" />
      </div>
    </Link>
  )
}

export function NotificationList() {
  const { data, /* fetchNextPage, hasNextPage, */ refetch } = useNotifications()
  // const { ref: refInView, inView } = useInView()

  // useEffect(() => {
  //   if (inView && hasNextPage) {
  //     fetchNextPage()
  //   }
  // }, [inView])

  return (
    <div id="notification_link">
      {data?.pages[0].length === 0 && <p className="empty">Vous n&apos;avez pas de nouvelle notification</p>}
      {data?.pages.map(
        (page, pageIndex) =>
          page?.map((notification, i) => {
            const itemProps = page.length === i + 1 ? { /* ref: refInView, */ className: "inview" } : {}

            return (
              <NotificationDetail
                refetch={refetch}
                pageIndex={pageIndex}
                itemProps={itemProps}
                key={notification.id}
                id={notification.id}
              />
            )
          })
      )}
    </div>
  )
}

export function NotificationsMini() {
  const dark = useSelector((state: RootState) => state.theme.dark)
  const { show: showNotif } = useSelector((state: any) => state.notif)
  const dispatch = useDispatch()
  const count = useNotificationCount().data ?? 0
  const queryClient = useQueryClient()

  // Close notification on escape
  const handleClose = useCallback((e: any) => {
    if (e.key === "Escape") {
      dispatch(hideNotification())
    }
  }, [])

  useEventListener("keydown", handleClose)

  const removeAll = () => {
    notificationsAreSeen().then(() => {
      dispatch(hideNotification())
      queryClient.invalidateQueries({ queryKey: ["notification-count"] })
      queryClient.resetQueries({ queryKey: ["notification"] })
    })
  }

  return (
    <div className={showNotif ? "notifications_container" : "notifications_container hide"}>
      <div className={dark ? "notifications_content card dark-before" : "notifications_content card"}>
        <div
          style={{
            minHeight: count ?? NOTIF_API_LIMIT > 0 ? `${NOTIF_SIZE * NOTIF_API_LIMIT}px` : `${count * NOTIF_SIZE}px`,
          }}
        >
          <NotificationList />
        </div>
        {!!count && (
          <IonButton className="notif_erase" onClick={removeAll}>
            Effacer les notifications
          </IonButton>
        )}
      </div>
    </div>
  )
}
