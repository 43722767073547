import { API } from "./axioshelper"
import { RentInfos } from "../types/common"

export async function fetchRentInfos() {
  return await API.request<RentInfos>({
    method: "get",
    url: "rent",
  }).then((response) => response.data)
}

export default fetchRentInfos
