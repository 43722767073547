import consola from "consola"
import { Storage } from "@ionic/storage"
import { toast } from "react-toastify"
import { setTokens, unsetAuth } from "../stores/actions/authActions"
import store from "../stores/store"
import { API } from "./axioshelper"

export async function logout() {
  return await new Promise((resolve) => {
    // suppr tokens dans redux
    const localStore = new Storage()
    localStore.create()
    localStore.set("loggedOut", true)
    store.dispatch(unsetAuth())
    resolve(null)
  })
}

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

let isRefreshing = false
let refreshSubscribers: any[] = []

export async function alive() {
  return API.request({
    method: "get",
    url: "alive",
  })
}

/**
 * On intercepte la requête ici
 *
 * C'est ici qu'on va ajouter les tokens d'authentification
 * On va également directement connecter l'utilisateur si on voit que ceux-ci sont expirés
 */
API.interceptors.request.use(
  (config: any) => {
    // Récupère l'access_token
    const accessToken = store.getState().auth.access_token

    // Avant requête API on vérifie l'expiration de nos tokens
    const expireDate = store.getState().auth.expire_date
    if (expireDate && expireDate < Date.now()) {
      consola.warn("Token expiré")
      consola.debug({ config })

      // On retourne une promesse qui ne sera jamais résolu
      return new Promise(() => {
        // Suppression REMEMBER ME email et password + deconnexion
        logout()
      })
    }

    // Headers par default
    const defaultHeaders = {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${accessToken}`,
    }

    return {
      ...config,
      headers: { ...defaultHeaders, ...config.headers },
    }
  },
  async (error) => await Promise.reject(error)
)

// Call every stored request waiting for a new access token, with a given one
const retryRequests = (token: string) => {
  refreshSubscribers.map((callback) => callback(token))
  refreshSubscribers = []
}

// Store a request waiting for a new access token
const subscribeTokenRefresh = (callback: (access_token: string) => void) => {
  refreshSubscribers.push(callback)
}

/**
 * On intercepte la réponse ici
 *
 * C'est ici qu'on va refresh les tokens d'authentification si celui d'accès
 * n'est plus valide, mais celui de refresh l'est encore, dans le cas où une requête
 * retourne une 401
 */
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      throw Object.assign(new Error("errors.error_network"), { code: 404 })
    }

    const { status, config } = error.response

    if (status === 503 && !error.request.responseURL.endsWith("/alive")) {
      alive().catch((res) => {
        if (res.response.status >= 500) {
          toast.error("Une erreur s'est produite veuillez réessayer plus tard", {
            position: "bottom-center",
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          logout()
        }
      })
    }
    // GESTION 401
    else if (status === 401 && config.url !== "oauth/v2/token" && config.url !== "impersonate") {
      const rememberMe = store.getState().auth.remember_me

      if (!rememberMe) {
        logout()
      }

      // Si pas déjà en train de rafraichir le token
      if (!isRefreshing && rememberMe) {
        consola.info("Refreshing access token")
        isRefreshing = true

        // On demande de nouveaux token
        const refreshToken = store.getState().auth.refresh_token

        API.request({
          method: "post",
          url: "oauth/v2/token",
          data: {
            grant_type: "refresh_token",
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            refreshToken,
          },
        }).then(
          (response) => {
            // On a récupéré de nouveaux tokens
            consola.error("Tokens refreshed successfully")
            const tokens = response.data
            isRefreshing = false

            store.dispatch(setTokens(tokens.access_token, tokens.refresh_token, tokens.expire_date))

            // Relancer les requêtes
            retryRequests(tokens.access_token)
          },
          () => {
            // On a pas récupéré de nouveaux tokens
            isRefreshing = false
            consola.error("Couldn't refresh tokens")
            logout()
          }
        )
      }

      // Stocke la requête pour la relancer plus tard avec le bon token
      const originalRequest = config
      const retryOrigReq = new Promise((resolve) => {
        subscribeTokenRefresh((access_token: string) => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`
          resolve(API(originalRequest))
        })
      })

      return await retryOrigReq
    }
    return await Promise.reject(error)
  }
)

export async function fakeRequest(success = true, delay = 250) {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve(true)
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(false)
      }
    }, delay)
  })
}

export function getAppVersion() {
  return API.request({
    method: "get",
    url: "config/getMobileVersion",
  })
}
