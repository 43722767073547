import { NotificationsActions } from "../actions/notificationsActions"

const notif: NotificationsActions = {
  type: "",
  show: false,
  waitingNotifIds: [],
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function NotificationsReducer(state: NotificationsActions = notif, action: Partial<NotificationsActions>): any {
  switch (action.type) {
    case "TOGGLE_NOTIF":
      return {
        ...state,
        show: !state.show,
      }

    case "HIDE_NOTIF":
      return {
        ...state,
        ...action,
      }

    case "ADD_WAITING_NOTIF": {
      return {
        ...state,
        waitingNotifIds: [...(state.waitingNotifIds || []), ...(action.waitingNotifIds || [])]
      }
    }

    case "REMOVE_WAITING_NOTIF": {
      return {
        ...state,
        waitingNotifIds: state.waitingNotifIds.filter(id => !action.waitingNotifIds?.includes(id)) || []
      }
    }

    default:
      return state
  }
}

export default NotificationsReducer
