import { IonIcon, IonItem } from "@ionic/react"
import React from "react"
import { useSelector } from "react-redux"
import { setDarkTheme, setLightTheme } from "stores/actions/themeActions"
import store from "stores/store"
import { type RootState } from "stores/reducers"
import sombre from "../theme/images/moon-outline.svg"
import clair from "../theme/images/sunny-outline.svg"
import "./DarkToggle.scss"

function DarkToggle() {
  const theme = useSelector((state: RootState) => state.theme.dark)

  const toggleDarkModeHandler = (e: any) => {
    if (e.target.checked) {
      store.dispatch(setDarkTheme())
    } else {
      store.dispatch(setLightTheme())
    }
  }

  return (
    <IonItem lines="none" aria-describedby="Accentuer le contraste" className="darkItem">
      <IonIcon
        slot="start"
        icon={theme ? clair : sombre}
        className="component-icon component-icon-dark"
        aria-hidden="true"
      />
      <label className="switch" htmlFor="dark" tabIndex={0}>
        <span className="sr-only">Mode sombre</span>
        <input
          id="dark"
          name="dark"
          type="checkbox"
          checked={theme}
          onChange={(e) => {
            toggleDarkModeHandler(e)
          }}
        />
        <span className="slider" />
      </label>
    </IonItem>
  )
}

export default DarkToggle
