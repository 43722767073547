import { API } from "./axioshelper"

export async function deleteToken(token = "") {
  return await API.request({
    method: "get",
    url: "logout",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export default deleteToken
