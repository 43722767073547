const configToast: any = null

// eslint-disable-next-line @typescript-eslint/default-param-last
export const ToastReducer = (state = configToast, action: any): any => {
  switch (action.type) {
    case "TOGGLE_ERROR_MESSAGE":
      // eslint-disable-next-line no-console
      console.log("error")
      return state

    case "TOGGLE_SUCCESS_MESSAGE":
      // eslint-disable-next-line no-console
      console.log("success")
      return state

    default:
      return state
  }
}

export default ToastReducer
