import React from "react"
import { IonIcon } from "@ionic/react"
import { useHistory } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import moment from "moment"
import Loader from "./Loader"
import Button from "./Button"
import iconCalendar from "../theme/images/calendar-outline.svg"
import { useWindowSize } from "../utils/hooks"
import "moment/min/locales"
import { useRequests } from "../hooks/request"
import { DemandType } from "../types/common"
import RequestStateLabel from "./RequestStateLabel"

export default function RequestsContent() {
  const router = useHistory()
  const size = useWindowSize()
  const { data: requests, isLoading, isFetching, fetchNextPage, hasNextPage } = useRequests()

  if (isLoading) {
    return (
      <div className="messenger_list-messages_container card ">
        <Skeleton height={300} borderRadius={20} />
      </div>
    )
  }

  if (undefined === requests || requests?.pages[0].length < 1) {
    return (
      <div className="messenger_list-messages_container card">
        <p>Vous n&apos;avez pas de demande en cours</p>
      </div>
    )
  }
  return (
    <div className="messenger_list-messages_container card">
      {size.width < 768 ? (
        requests?.pages.map((page: DemandType[]) =>
          page.map((request: any) => (
            <div // replace with button
              className="messenger_message-container"
              key={request.idRequest}
              onClick={() => {
                router.push({
                  pathname: `/demande/${request.idRequest}`,
                })
              }}
            >
              <div className="messenger_list-message_date-container">
                <IonIcon icon={iconCalendar} aria-hidden="true" />
                <p className="messenger_list-message_date">{moment(request.creationDate).locale("fr").format("LL")}</p>
              </div>
              <div className="messenger_list-message_content">
                {/* TODO: Add seen condition */}
                {/* {request.seen && (
                          <div className="messenger_new-message_container">
                            <p>Nouveau</p>
                          </div>
                        )} */}
                <p className="messenger_text">{request.comment}</p>
                {/* {request.file && <IonIcon icon={fileIcon} aria-hidden="true"></IonIcon>} */}
              </div>
              {request?.stateCode && (
                <div>
                  <p>
                    <RequestStateLabel label={request?.stateCode} />
                  </p>
                </div>
              )}
            </div>
          ))
        )
      ) : (
        <table>
          <caption className="sr-only">Liste de vos derniers messages, tel une boite de réception.</caption>
          <thead className="messenger_head_desktop">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Réfèrence de demande</th>
              <th scope="col">Catégorie de demande</th>
              <th scope="col" className="messenger_top-cell_message">
                Message
              </th>
              <th scope="col">État</th>
            </tr>
          </thead>
          <tbody>
            {requests?.pages.map((page: DemandType[]) =>
              page.map((request: any) => (
                <tr
                  className="messenger_row_dekstop focusable"
                  tabIndex={0}
                  title={request.comment}
                  onClick={() => {
                    router.push({
                      pathname: `/demande/${request.idRequest}`,
                    })
                  }}
                  key={request.idRequest}
                >
                  <th scope="row" className="messenger_cell_desktop messenger_date_desktop">
                    <IonIcon icon={iconCalendar} aria-hidden="true" />
                    <p className="messenger_list-message_date">
                      {moment(request.creationDate).locale("fr").format("LL")}
                    </p>
                  </th>
                  <td className="messenger_cell_desktop messenger_origin_desktop">
                    <p>{request.idRequest}</p>
                  </td>
                  <td className="messenger_cell_desktop messenger_type_desktop">
                    <p>{request.categoryLabel}</p>
                  </td>
                  <td className="messenger_cell_desktop messenger_message_desktop">
                    {/* TODO: Add seen condition */}
                    {/* {request.seen && (
                            <div className="messenger_new-message_container">
                              <p>Nouveau</p>
                            </div>
                          )} */}
                    <p className="messenger_text">{request.comment}</p>
                    {/* {request.file && <IonIcon icon={fileIcon} aria-hidden="true"></IonIcon>} */}
                  </td>
                  {request?.stateCode && (
                    <td className="messenger_cell_desktop messenger_status_desktop">
                      <div>
                        <p>
                          <RequestStateLabel label={request?.stateCode} />
                        </p>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
      {hasNextPage && (
        <Button
          type="button"
          className="load-more-btn"
          aria-label="Cliquer içi pour voir plus de messages."
          // disabled={!loadMore}
          onClick={() => fetchNextPage()}
        >
          {isLoading || isFetching ? <Loader /> : "Voir plus..."}
        </Button>
      )}
    </div>
  )
}
