import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import { type IonContentCustomEvent, type ScrollDetail } from "@ionic/core"
import * as Yup from "yup"
import { IonContent } from "@ionic/react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import Prelevement from "components/Prelevement"
import { toast } from "react-toastify"
import { resetEmail } from "api/account"
import { useQueryClient } from "@tanstack/react-query"
import * as actions from "../../stores/actions/promptActions"
import "react-toastify/dist/ReactToastify.css"
import { patchProfileInfosUpdate } from "../../api/profile"

import AuthLayout from "../../layouts/AuthLayout"
import Footer from "../../components/Footer"
import MyProfile from "../../components/MyProfile"
import Preference from "../../components/Preference"

import "./Profile.scss"

import { toggleErrorMessageAction, toggleSuccessMessageAction } from "../../stores/actions/toastMessageActions"
import { useAccount } from "../../hooks/user"

function Profile() {
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = useLocation()
  const [routeName, setRouteName] = useState("")
  const { data: user } = useAccount()
  const queryClient = useQueryClient()

  useEffect(() => {
    // Stock the pathname to use later, when the user leaves the Profile page. This allow us the use a dynamic pathname so that if it changes (eg. from /me to /profile), the customPrompt will still be shown.
    setRouteName(location.pathname)
    // Prepare Profile Mobile Content
    dispatch(
      actions.setPromptContent({
        ok: "Oui",
        cancel: "Non",
        text: "Vos modifications sur la page n'ont pas été enregistrées.",
        subtext: "Êtes-vous sûr(e) de vouloir quitter la page ?",
        // onOk and onCancel nous permet de choisir quel comportement adoptera les bouton Ok et Cancel (ex. Voulez vous quitter la page : onOk -> true (inversment pour onCancel) donc redirection OU Voulez vous rester sur la page onOk -> false donc pas de redirection)
        onOk: true,
        onCancel: false,
      })
    )
  }, [])

  const validationSchema: any = Yup.object().shape({
    // https://github.com/jaredpalmer/formik/issues/243
    landlinePhone: Yup.string().nullable()
      .min(10, "Le tel. fixe est trop court")
      .max(10, "Le tel. fixe est trop long")
      .test("isPhone", "Le tel. fixe ne doit comporter que des chiffres", (value) => !value || /^\d+$/.test(value))
      .notRequired(),
    mobilePhone: Yup.string()
      .min(10, "Le tel. fixe est trop court")
      .max(10, "Le tel. fixe est trop long")
      .test("isPhone", "Le tel. fixe ne doit comporter que des chiffres", (value) => !value || /^\d+$/.test(value))
      .required("Ce champ est obligatoire"),
    email: Yup.string()
      .test(
        "isEmptyEmail",
        "L'email est obligatoire et doit être différent de votre ancien email",
        (value) => value !== undefined
      )
      .email("Veuillez renseigner une adresse e-mail valide, en incluant le symbole '@'"),
    password: Yup.string()
      .notRequired()
      .min(8, "Le mot de passe est trop court")
      .max(50, "Le mot de passe est trop long")
      .when([], {
        is: (passwordConfirm: string, password: string, email: string) =>
          password !== passwordConfirm && email !== user!.email,
        then: () =>
          Yup.string()
            .required("Ce champ est obligatoire. Veuillez entrer votre mot de passe.")
            .matches(
              /^(?=.*[$&+,:;=?@#|<>.^*()%!-]).{8,}$/,
              "Au moins un caractère spécial est requis ($&+,:;=?@#|<>.^*()%!-)"
            )
            .matches(/^(?=.*\d).{8,}$/, "Au moins un caractère numérique est requis")
            .matches(/^(?=.*[^a-z]).{8,}$/, "Au moins un caractère minuscule est requis")
            .matches(/^(?=.*[A-Z])(?!.* ).{8,}$/, "Au moins un caractère majuscule est requis"),
      }),
    passwordConfirm: Yup.string().when(["password"], {
      is: (password: string, passwordConfirm: string) => password !== passwordConfirm,
      then: () =>
        Yup.string()
          .required("Ce champ est obligatoire. Veuillez confirmer votre mot de passe.")
          .matches(
            /^(?=.*[$&+,:;=?@#|<>.^*()%!-]).{8,}$/,
            "Au moins un caractère spécial est requis ($&+,:;=?@#|<>.^*()%!-)"
          )
          .matches(/^(?=.*\d).{8,}$/, "Au moins un caractère numérique est requis")
          .matches(/^(?=.*[^a-z]).{8,}$/, "Au moins un caractère minuscule est requis")
          .matches(/^(?=.*[A-Z])(?!.* ).{8,}$/, "Au moins un caractère majuscule est requis")
          .test(
            "passwords-match",
            "Les mots de passe doivent être identiques",
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            (value: any) => value && formik.values.password === value
          ),
    }),
  })

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: user?.email || "",
      password: "",
      passwordConfirm: "",
      landlinePhone: user?.landlinePhone || "",
      mobilePhone: user?.mobilePhone || "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {
      let userData: any = {}
      Object.entries(formik.values).forEach(([key, value]) => {
        if (key === "email" && value !== user!.email) {
          // send to account/reset-mail
          resetEmail(value as string)
          .then(() => {
            toast.success(
              "Nous vous avons envoyé un email afin de finaliser votre demande de modifcation d'adresse mail. Ce lien est valide pendant 1 heure.",
              {
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                className: "toast-success_reset-email",
                style: {
                  fontSize: "1.3rem",
                  padding: "1.7rem 1rem",
                },
              }
            )
          })
          .catch(() => {
            toast.error(
              "Une erreur est survenue, merci d'utiliser un autre email ou de contacter nos services via notre formulaire.",
            )
          })
        }

        if (key === "landlinePhone" || value !== "") {
          const { email, ...other } = userData
          userData = {
            ...other,
            [key]: value,
          }
        }
      })

      patchProfileInfosUpdate(userData)
        .then((res) => {
          // eslint-disable-next-line no-console
          console.debug(res.status)
          queryClient.refetchQueries({ queryKey: ["user_account"] })
          dispatch<any>(toggleSuccessMessageAction())
        })
        .then(() => dispatch(actions.setCheckBlocking(false)))
        .catch(() => dispatch<any>(toggleErrorMessageAction()))
    },
  })

  useEffect(() => {
    if (location.pathname === routeName) {
      dispatch(actions.setCheckBlocking(formik.dirty))
    }
  }, [formik.dirty, dispatch, location, routeName])

  const [classButton, setclassButton] = useState("submit-button_profil--fixed")

  const handleScroll = (event: IonContentCustomEvent<ScrollDetail>) => {
    const rect = event.target.getBoundingClientRect();
    if (event.detail.scrollTop < rect.bottom - 100) {
      setclassButton("submit-button_profil--fixed")
    } else {
      setclassButton("")
    }
  }

  return (
    <>
      <Helmet>
        <title>Mon profil - Espace Locataire - Vilogia</title>
        <meta
          name="description"
          content="Le profil rassemble des informations personnelles sur l'utilisateur. Il a la possibilité de les mettre à jour, et de télécharger une demande de prélèvement."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <IonContent scrollEvents onIonScroll={handleScroll}>
          <div className="profile_form">
            <div className="container-section-width--padded profilDisplay">
              <MyProfile formik={formik} classButton={classButton} />
              <div className="profilDisplay-right">
                <Prelevement />
                <Preference />
              </div>
            </div>
          </div>
          <Footer />
        </IonContent>
      </AuthLayout>
    </>
  )
}

export default Profile
