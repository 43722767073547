import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { useConfig } from "hooks/config"
import "./HotNews.scss"

function HotNews() {
  const { data: config } = useConfig()
  const dark = useSelector((state: RootState) => state.theme.dark)

  if (!config?.news) {
    return null
  }

  return (
    <div style={dark ? undefined : { backgroundColor: config.primaryColor }} className="hot-news">
      <div className="hot-news_text" dangerouslySetInnerHTML={{ __html: config.news }} />
    </div>
  )
}

export default HotNews
