import { API } from "./axioshelper"

export async function fetchLegalNotice() {
  return await API.request({
    method: "get",
    url: "/legalNotice",
  })
}

export async function fetchCgu() {
  return await API.request({
    method: "get",
    url: "/legalNotice/cgu",
  })
}

export async function fetchPdc() {
  return await API.request({
    method: "get",
    url: "/legalNotice/pdc",
  })
}

export async function fetchAccessibility() {
  return await API.request({
    method: "get",
    url: "/legalNotice/accessibility",
  })
}
