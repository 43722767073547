import { IonContent, IonPage } from "@ionic/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { type ScrollDetail } from "@ionic/core"
import HotNews from "components/HotNews"
import { hideNotification } from "stores/actions/notificationsActions"
import { useQueryClient } from "@tanstack/react-query"
import { NotificationsMini } from "../components/NotificationsMini"
import { useRequireAuth, useWindowSize } from "../utils/hooks"
import HeaderConnected from "../components/HeaderConnected"

// let prevScrollTop = 0;

function AuthLayout(props: { children: any }) {
  const { children } = props
  const auth = useRequireAuth()
  const dispatch = useDispatch()
  const [showHotNews, setshowHotNews] = React.useState(true)
  const size = useWindowSize()
  const queryClient = useQueryClient()
  const { show: showNotif } = useSelector((state: any) => state.notif)
  // const [shrink, setshrink] = useState(false);
  // const [shrinkTab, setshrinkTab] = useState(false);
  // const ref = useRef(null);

  const isDesktop = size.width > 1135

  const onScroll = (event: CustomEvent<ScrollDetail>) => {
    // Could not make it work with CustomEvent<ScrollDetail> type
    const { scrollTop } = event.detail
    const hotNews = document.querySelector(".hot-news")
    const shouldShowHotNews =
      // Should show if didn't scroll past 50px
      scrollTop < (hotNews?.clientHeight || 50)
    // ||
    // OR Should show if scrolling up
    // prevScrollTop > scrollTop;

    // prevScrollTop = scrollTop;
    setshowHotNews(shouldShowHotNews)
  }

  const closeBackdrop = () => {
    dispatch(hideNotification())
    queryClient.invalidateQueries({ queryKey: ["notification-count"] })
    queryClient.resetQueries({ queryKey: ["notification"] })
  }

  if (!auth.access_token) {
    return (
      <IonPage>
        <IonContent>
          Loading...
          {/* (en attendant de vérifier si l'user est connecté) */}
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage>
      {(isDesktop || showHotNews) && <HotNews />}
      <HeaderConnected />
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      {showNotif && <button type="button" tabIndex={1} className="backdrop_custom_shadow" onClick={closeBackdrop} />}
      {/* replace with button */}
      <NotificationsMini />
      <IonContent scrollEvents onIonScroll={onScroll} id="content">
        {children}
      </IonContent>
    </IonPage>
  )
}

export default AuthLayout
