import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueries,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query"
import { fetchNotifications, fetchNotification, fetchNotificationCounter } from "../api/notification"
import { NotificationDetails, Notification } from "../types/common"

export const NOTIF_API_LIMIT = 4 // limit defined in backoffice

export function useNotifications(): UseInfiniteQueryResult<InfiniteData<Notification[]>> {
  return useInfiniteQuery({
    queryKey: ["notification"],
    queryFn: async ({ pageParam = 0 }) => fetchNotifications(pageParam).then((res) => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => (lastPage.length === NOTIF_API_LIMIT ? allPages.length + 1 : undefined),
  })
}

export function useNotification(id: number): UseQueryResult<NotificationDetails> {
  return useQuery({
    queryKey: ["notification", { type: id }],
    queryFn: async () => fetchNotification(id).then((res) => res.data),
  })
}

export function useNotificationCount(): UseQueryResult<number> {
  return useQuery({
    queryKey: ["notification-count"],
    queryFn: async () => fetchNotificationCounter().then((res) => res.data.count),
  })
}

export function useManyNotification(ids: number[]) {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ["notification", { type: id }],
      queryFn: async () => fetchNotification(id).then((res) => res.data),
    })),
  })
}
