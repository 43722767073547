import { type PromptAction } from "../actions/promptActions"

export interface PromptActionsData {
  checkBlocking?: boolean
  ok: any
  cancel: any
  text: any
  subtext: any
  onOk: any
  onCancel: any
}

const prompt: PromptActionsData = {
  checkBlocking: false,
  ok: "",
  cancel: "",
  text: "",
  subtext: "",
  onOk: "",
  onCancel: "",
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const PromptReducer = (state: PromptActionsData = prompt, action: PromptAction): any => {
  switch (action.type) {
    case "PROMPT_SET_CHECKBLOCKING":
      return {
        ...state,
        checkBlocking: action.data,
      }

    case "PROMPT_SET_CONTENT":
      if (typeof action.data === "boolean") {
        return state
      }

      return {
        ...state,
        ok: action.data.ok,
        cancel: action.data.cancel,
        text: action.data.text,
        subtext: action.data.subtext,
        onOk: action.data.onOk,
        onCancel: action.data.onCancel,
      }

    default:
      return state
  }
}
