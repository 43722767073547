import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { isPlatform, useIonAlert } from "@ionic/react"
import { useLocationValues } from "utils/hooks"
import { NativeMarket } from "@capgo/native-market"
import { App as AppInfo } from "@capacitor/app"
import { Config } from "../types/common"
import { FetchConfig } from "../api/misc"
import { getAppVersion } from "../api/helper"

export function useConfig(): UseQueryResult<Config> {
  return useQuery({
    queryKey: ["config"],
    queryFn: async () => FetchConfig(),
  })
}

export function useAppVersion(): UseQueryResult<any> | any {
  const location = useLocationValues()
  const [presentAlert] = useIonAlert()
  return useQuery({
    queryKey: ["AppVersion"],
    queryFn: async () =>
      getAppVersion()
        .then((res: any) => {
          const desiredAndroidVersion = res.data?.androidVersion?.split(".")
          const desiredIosVersion = res.data?.iosVersion?.split(".")
          if (location === "/nouvelle-demande") throw Error("Not permitted here.")
          AppInfo.getInfo()
            .then((appInfosData) => {
              if (appInfosData) {
                const userVersion = appInfosData?.version.split(".")
                // Major version check
                if (
                  (isPlatform("android") && Number(userVersion[0]) < Number(desiredAndroidVersion[0])) ||
                  (isPlatform("ios") && Number(userVersion[0]) < Number(desiredIosVersion[0]))
                ) {
                  presentAlert({
                    header: "Une mise à jour majeure est disponible",
                    cssClass: "custom-updateapp-alert",
                    subHeader:
                      "Pour une utilisation optimale de l'application, merci de télécharger la mise à jour en cliquant sur le bouton ci-dessous.",
                    buttons: [
                      {
                        cssClass: "custom-btn-updateapp-alert",
                        text: "Télécharger",
                        role: "confirm",
                        handler: async () => {
                          await NativeMarket.openStoreListing({
                            appId: appInfosData.id,
                            country: "FR",
                          })
                        },
                      },
                    ],
                    backdropDismiss: false,
                  })
                }
                // Minor version check
                else if (
                  (isPlatform("android") &&
                    Number(userVersion[0]) === Number(desiredAndroidVersion[0]) &&
                    Number(userVersion[1]) < Number(desiredAndroidVersion[1])) ||
                  (isPlatform("ios") &&
                    Number(userVersion[0]) === Number(desiredAndroidVersion[0]) &&
                    Number(userVersion[1]) < Number(desiredIosVersion[1]))
                ) {
                  presentAlert({
                    header: "Une mise à jour est disponible",
                    cssClass: "custom-updateapp-alert",
                    subHeader: "Souhaitez-vous mettre à jour l'application ?*",
                    message:
                      "*Pour une utilisation optimale de l'application, nous vous recommandons de télécharger cette mise à jour.",
                    buttons: [
                      {
                        cssClass: "custom-btn-updateapp-alert-cancel",
                        text: "Plus tard",
                        role: "cancel",
                        handler: () => {
                          console.log("=> App update (patch) cancelled.")
                        },
                      },
                      {
                        cssClass: "custom-btn-updateapp-alert",
                        text: "Télécharger maintenant",
                        role: "confirm",
                        handler: async () => {
                          await NativeMarket.openStoreListing({
                            appId: appInfosData.id,
                            country: "FR",
                          })
                        },
                      },
                    ],
                  })
                }
                // Patch version check
                else if (
                  (isPlatform("android") &&
                    Number(userVersion[0]) === Number(desiredAndroidVersion[0]) &&
                    Number(userVersion[1]) === Number(desiredAndroidVersion[1]) &&
                    Number(userVersion[2]) < Number(desiredAndroidVersion[2])) ||
                  (isPlatform("ios") &&
                    Number(userVersion[0]) === Number(desiredAndroidVersion[0]) &&
                    Number(userVersion[1]) === Number(desiredAndroidVersion[1]) &&
                    Number(userVersion[2]) < Number(desiredIosVersion[2]))
                ) {
                  presentAlert({
                    header: "Une mise à jour est disponible",
                    cssClass: "custom-updateapp-alert",
                    subHeader: "Souhaitez-vous mettre l'application à jour maintenant ?*",
                    message:
                      "*Cette mise à jour n'est pas obligatoire mais fortement recommandée afin d'utiliser l'application dans des conditions optimales.",
                    buttons: [
                      {
                        cssClass: "custom-btn-updateapp-alert-cancel",
                        text: "Plus tard",
                        role: "cancel",
                        handler: () => {
                          console.log("=> App update (patch) cancelled.")
                        },
                      },
                      {
                        cssClass: "custom-btn-updateapp-alert",
                        text: "Télécharger maintenant",
                        role: "confirm",
                        handler: async () => {
                          await NativeMarket.openStoreListing({
                            appId: appInfosData.id,
                            country: "FR",
                          })
                        },
                      },
                    ],
                  })
                }
              }
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => {
          console.log("errGetVersion:", err)
        }),
  })
}

export default useConfig
