import React from "react"
import "./Notifications.scss"

import { IonButton, IonIcon } from "@ionic/react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router"
import { useDispatch } from "react-redux"
import { useQueryClient } from "@tanstack/react-query"
import { useNotificationCount } from "hooks/notifications"
import AuthLayout from "../../layouts/AuthLayout"
import Footer from "../../components/Footer"
import { NotificationList } from "../../components/NotificationsMini"
import iconBell from "../../theme/images/icon_bell.svg"
import { notificationsAreSeen } from "../../api/notification"
import { hideNotification } from "../../stores/actions/notificationsActions"

function Notifications() {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const count = useNotificationCount().data ?? 0

  const removeAll = () => {
    notificationsAreSeen().then(() => {
      dispatch(hideNotification())
      queryClient.invalidateQueries({ queryKey: ["notification-count"] })
      queryClient.resetQueries({ queryKey: ["notification"] })
    })
  }

  return (
    <>
      <Helmet>
        <title>Mes notifications - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="Ensemble des nouvelles notifications, ainsi vous êtes tenu au courant des dernières activités réaliser sur votre compte."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="notifications_page">
          <div className="container-section-width--padded">
            <section className="notifications_title-container" title="Notifications">
              <IonIcon icon={iconBell} aria-hidden="true" />
              <h1>Notifications</h1>
            </section>
            <section className="notifications_list">
              <NotificationList />
              {!!count && (
                <IonButton className="notif_erase" onClick={removeAll}>
                  Effacer les notifications
                </IonButton>
              )}
            </section>
          </div>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}

export default Notifications
