import React from "react"
import "./InsuranceStatus.scss"
import { IonIcon } from "@ionic/react"
import moment from "moment"
import { useHistory } from "react-router"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { Home } from "types/common"
import closeSharp from "../theme/images/close-sharp.svg"
import check from "../theme/images/checkmark-outline.svg"
import Button from "./Button"
import "moment/min/locales"

type InsuranceStatusType = {
  homeDetails: Home | undefined
  isLoading: boolean
  display?: string
  origin?: string
}

const defaultProps = {
  display: undefined,
  origin: undefined,
}

export default function InsuranceStatus({ homeDetails, display, origin, isLoading }: InsuranceStatusType) {
  const history = useHistory()
  const startInsurance = moment(homeDetails?.insurance?.startDate)
    .locale("fr")
    .format("LL")

  const endInsurance = moment(homeDetails?.insurance?.endDate)
    .locale("fr")
    .format("LL")

  const getInsuranceDemand = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    const state = {
      messageObject: "ASS",
      messageAbout: "001",
    }
    history.push("/nouvelle-demande", state)
  }

  return isLoading ? (
    <div style={{ display: "block" }}>
      <Skeleton count={1} height={40} borderRadius={15} />
    </div>
  ) : homeDetails && homeDetails.kindCode === "LOG" ? (
    <div
      className={`insurrance_container ${origin && origin === "dashboard" ? "card " : ""} 
      ${homeDetails.insurance?.assured ? "is-assured" : ""}`}
    >
      {homeDetails.insurance?.assured && <IonIcon icon={check} className="check-icon" aria-hidden="true" />}
      {homeDetails.insurance?.assured ? (
        display !== "all" ? (
          <p>
            Assuré
            <br />
            <span className="span_insurrance">échéance le {endInsurance}</span>
          </p>
        ) : (
          <p className="span_insurrance">
            Assuré du {startInsurance} au {endInsurance}
          </p>
        )
      ) : (
        <div className="no_insurrance">
          <div>
            <IonIcon icon={closeSharp} className="close-icon" aria-hidden="true" />
            <p>Non assuré</p>
          </div>
          <Button
            className="renew-insurrance_button"
            aria-label="Je renouvelle mon attestation."
            // href="/nouvelle-demande?&param1=ASS&param2=001"
            onClick={(e) => {
              getInsuranceDemand(e)
            }}
          >
            Renouveler mon attestation
          </Button>
        </div>
      )}
    </div>
  ) : null
}

InsuranceStatus.defaultProps = defaultProps
