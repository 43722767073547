import { Redirect } from "react-router-dom"
import { IonApp, IonRouterOutlet, setupIonicReact, isPlatform, createAnimation } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.scss"
import "./theme/globals.scss"
import React, { useEffect, useState } from "react"
import OneSignal from "onesignal-cordova-plugin"
import { ToastContainer } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { useHomeIds } from "hooks/myHome"
import { setOneSignalUuid } from "api/account"
import { notificationIsSeen } from "api/notification"
import { useQueryClient } from "@tanstack/react-query"
import { addWaitingNotifId, removeWaitingNotifId } from "stores/actions/notificationsActions"
import AppUrlListener from "./components/AppUrlListener"
import ResetMailValidation from "pages/auth/ResetMailValidation"
import PaymentForm from "./pages/paymentForm"
import PaymentValidation from "./pages/PaymentValidation"
import { type RootState } from "./stores/reducers"
import store from "./stores/store"
import { setDarkTheme, setIsSliderOpen, setLightTheme } from "./stores/actions/themeActions"
import SliderInfosIntro from "./components/SliderInfosIntro"
import { SentryRoute, sentryHistory, initSentry } from "./Sentry"
import ErreurConso from "./pages/ErreurConso"
import Profile from "./pages/auth/Profile"
import Dashboard from "./pages/auth/Dashboard"
import Contact from "./pages/auth/Contact"
import { useAuth } from "./utils/hooks"
import Fallback from "./pages/fallback"
import Login from "./pages/guest/Login"
import Signup from "./pages/guest/Signup"
import SignupCreate from "./pages/guest/SignupCreate"
import MailConfirmation from "./pages/guest/MailConfirmation"
import LostPassword from "./pages/guest/LostPassword"
import LostPasswordConfirmation from "./pages/guest/LostPasswordConfirmation"
import ResetPassword from "./pages/guest/ResetPassword"
import { Menu } from "./components/Menu"
import Documents from "./pages/auth/Documents"
import RentPage from "./pages/auth/RentPage"
import Accomodation from "./pages/auth/Accomodation"
import NewRequest from "./pages/auth/NewRequest"
import Requests from "./pages/auth/Requests"
import News from "./pages/auth/News"
import NewArticle from "./pages/auth/NewArticle"
import Notifications from "./pages/auth/Notifications"
import RequestDetail from "./pages/auth/RequestDetail"
import PDF from "./pages/pdf"

import SliderIntro from "./components/SliderIntro"
import PDC from "./pages/guest/PDC"
import CGU from "./pages/guest/CGU"
import Accessibility from "./pages/guest/Accessibility"
import Sitemap from "./pages/guest/Sitemap"
import CustomPrompt from "./components/CustomPrompt"
import FaqPage from "./pages/auth/FaqPage"
import { useAppVersion } from "./hooks/config"
import { useAccount } from "./hooks/user"
import Impersonation from "./pages/Impersonation"

function App() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  setupIonicReact({
    mode: "md",
    navAnimation: (baseEl, opts) => {
      const content = opts.leavingEl
      const anim = createAnimation()
        .addElement(content)
        .duration(300)
        .iterations(1)
        .easing("ease-out")
        .fromTo("transform", "translateY(0)", "translateY(0)")
      return anim
    },
  })

  if (isPlatform("capacitor")) {
    const OneSignalInit = (): void => {
      OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_APP_ID as string)
      OneSignal.setLaunchURLsInApp(true);
      OneSignal.promptForPushNotificationsWithUserResponse(false, (response: boolean) => {
        if (response) {
          console.log("User accepted notifications:", response)
        } else {
          console.error("User refused notifications:", response)
        }
      })

      // NOTE: do not delete this
      OneSignal.getDeviceState((deviceState) => {
        console.log("deviceState:", deviceState)
      })

      OneSignal.addSubscriptionObserver(async (subscription) => {
        try {
          await setOneSignalUuid(subscription.to.userId as string)
        } catch (error) {
          console.error(error)
        }
      })

      OneSignal.setNotificationOpenedHandler((jsonData) => {
        // On stock dans redux l'id de la notif pour l'envoyer en "lu" plus tard
        // car l'appel api au click sur la notif push ne fonctionne pas
        const data: any = jsonData.notification.additionalData
        const { notification_id: notificationId, app_url: appUrl } = data

        dispatch(addWaitingNotifId(notificationId));
        sentryHistory.push(appUrl)
      })
    }
    OneSignalInit()
  }


  const { data: user } = useAccount()
  if (isPlatform("capacitor")) {
    useAppVersion()
  }

  const auth = useAuth()

  const updateThemeClass = (dark: boolean) => {
    if (dark) {
      document.body.classList.remove("light")
      document.body.classList.add("dark")
    } else {
      document.body.classList.remove("dark")
      document.body.classList.add("light")
    }
  }

  const state = useSelector((rootstate: RootState) => rootstate)

  useEffect(() => {
    if (state.theme.dark !== undefined) {
      updateThemeClass(state.theme.dark)
    } else {
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        if (isPlatform("android")) {
          updateThemeClass(false)
          store.dispatch(setLightTheme())
        } else {
          updateThemeClass(true)
          store.dispatch(setDarkTheme())
        }
      }
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
        updateThemeClass(e.matches)
      })
    }
  }, [state.theme.dark])

  initSentry()

  useEffect(() => {
    if (user?.lookedAtSlider === false) {
      dispatch(setIsSliderOpen())
    }
  }, [user])

  useHomeIds()

  useEffect(() => {
    state.notif.waitingNotifIds.forEach((id: number) => {
      notificationIsSeen(id).finally(() => {
        queryClient.invalidateQueries({ queryKey: ["notification-count"] })
        queryClient.resetQueries({ queryKey: ["notification"] })
        dispatch(removeWaitingNotifId(id))
      })
    })
  }, [state.notif.waitingNotifIds])

  // eslint-disable-next-line import/extensions
  const ReactQueryDevToolsProduction = React.lazy(async () => import("@tanstack/react-query-devtools/build/modern/production.js")
    .then((d) => ({
      default: d.ReactQueryDevtools,
    }))
  )
  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    const envIsTrue = process.env.REACT_APP_DEBUG_QUERIES?.trim() === "true"
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools(old => !old && envIsTrue)
  }, [])
  return (
    <IonApp>
      <IonReactRouter
        history={sentryHistory}
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        {!auth.access_token && !state.introInfos.lookedAtInfosIntro && <SliderInfosIntro />}
        {auth.access_token && state.theme.sliderOpen && <SliderIntro />}
        {auth.access_token && <Menu />}
        <CustomPrompt />
        <AppUrlListener />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toast_container_profile"
        />
        <IonRouterOutlet id="router-outlet">
          {/* Misc routes */}
          <SentryRoute exact path="/pdf" component={PDF} />
          <SentryRoute exact path="/impersonate/:token" component={Impersonation} />

          {/* Guest routes */}
          <SentryRoute exact path="/login" component={Login} />
          <SentryRoute exact path="/signup" component={Signup} />
          <SentryRoute exact path="/signup-create" component={SignupCreate} />
          <SentryRoute exact path="/mail-confirmation" component={MailConfirmation} />
          <SentryRoute exact path="/lost-password" component={LostPassword} />
          <SentryRoute exact path="/lost-password-confirmation" component={LostPasswordConfirmation} />
          <SentryRoute exact path="/reset-password" component={ResetPassword} />
          <SentryRoute path="/pdc" component={PDC} />
          <SentryRoute path="/cgu" component={CGU} />
          <SentryRoute path="/accessibility" component={Accessibility} />
          <SentryRoute path="/sitemap" component={Sitemap} />

          <SentryRoute exact path="/">
            {auth.access_token ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
          </SentryRoute>

          {/* Auth routes */}
          <SentryRoute exact path="/dashboard" component={Dashboard} />
          <SentryRoute exact path="/documents" component={Documents} />
          <SentryRoute exact path="/loyer" component={RentPage} />
          <SentryRoute exact path="/me" component={Profile} />
          <SentryRoute exact path="/reset-email/:validationCode" component={ResetMailValidation} />
          <SentryRoute exact path="/logement" component={Accomodation} />
          <SentryRoute exact path="/contact" component={Contact} />
          <SentryRoute exact path="/faq" component={FaqPage} />
          <SentryRoute exact path="/nouvelle-demande" component={NewRequest} />
          <SentryRoute exact path="/demandes" component={Requests} />
          <SentryRoute exact path="/demande/:id" component={RequestDetail} />
          <SentryRoute exact path="/actualites" component={News} />
          <SentryRoute exact path="/actualites/:id" component={NewArticle} />
          <SentryRoute exact path="/notifications" component={Notifications} />
          <SentryRoute exact path="/erreur-consommation" component={ErreurConso} />
          <SentryRoute component={Fallback} />

          {/* Payzen routes */}
          <SentryRoute exact path="/payzen-form" component={PaymentForm} />
          <SentryRoute exact path="/payzen-validation" component={PaymentValidation} />
        </IonRouterOutlet>
      </IonReactRouter>
      {showDevtools ? (
        <React.Suspense fallback={null}>
          <ReactQueryDevToolsProduction />
        </React.Suspense>
      ) : null}
    </IonApp>
  )
}

export default App
