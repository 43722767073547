import React, { useRef, useState, useEffect } from "react"
import { IonIcon, IonToast } from "@ionic/react"

import { pdfjs, Document, Page } from "react-pdf"
import { useHistory } from "react-router"

import "./PDFViewer.scss"

import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { useConfig } from "hooks/config"
import { useAuth } from "../utils/hooks"
import error from "../theme/images/close-outline.svg"
import check from "../theme/images/check.svg"
import Loader from "./Loader"
import { dl } from "../utils/functions"

// TODO Replace by static file
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface PDFViewerProps {
  filename: string
  dlName: string
}

function PDFViewer({ filename, dlName }: PDFViewerProps) {
  const router = useHistory()

  const [numPages, setNumPages] = useState(0)
  const [clickState, setClickState] = useState<"initial" | "loading" | "success" | "error">("initial")
  const dark = useSelector((state: RootState) => state.theme.dark)
  const { data: config } = useConfig()

  useEffect(() => {
    setClickState("initial")

    return () => {
      setClickState("initial")
    }
  }, [])

  const auth = useAuth()

  const token = auth.access_token

  const pdfWrapperRef = useRef(null)

  // Methods

  const goBack = () => {
    // Implement custom logic here if needed
    router.goBack()
  }

  return (
    <div className="pdf-viewer" ref={pdfWrapperRef}>
      <div className="pdf-viewer__header">
        <div>
          <button type="button" onClick={goBack}>
            Retour
          </button>
        </div>
        <span className="pdf-viewer__header__name">{filename}</span>
        <div>
          <button
            type="button"
            onClick={() => {
              dl(filename, dlName, setClickState)
            }}
          >
            {clickState === "loading" ? (
              <Loader />
            ) : clickState === "success" ? (
              <IonIcon
                aria-hidden="true"
                icon={check}
                title="Document téléchargé"
                className="download-btn__button__icon"
                style={dark || !config ? undefined : { color: config.primaryColor }}
              />
            ) : clickState === "error" ? (
              <IonIcon
                aria-hidden="true"
                icon={error}
                title="Erreur lors du téléchargargement. Veuillez réessayer."
                className="download-btn__button__icon"
                style={dark || !config ? undefined : { color: config.primaryColor }}
              />
            ) : (
              clickState === "initial" && "Télécharger"
            )}
          </button>
        </div>
      </div>
      {filename && (
        <Document
          file={{
            url: `${process.env.REACT_APP_BACKEND}/api/document/download/${filename}`,
            // @ts-expect-error
            httpHeaders: {
              Authorization: `Bearer ${token}`,
            },
          }}
          onLoadSuccess={(pdfDocumentProxy) => {
            setNumPages(pdfDocumentProxy.numPages)
          }}
        >
          {[...Array(numPages)].map((x, index) => (
            <Page
              pageNumber={index + 1}
              key={x}
              className="pdfCanvas"
              width={
                // @ts-expect-error
                (pdfWrapperRef.current?.getBoundingClientRect().width ?? 0) * 1 || undefined
              }
            />
          ))}
        </Document>
      )}
      {clickState === "success" ? (
        <IonToast
          isOpen
          translucent
          animated
          duration={4000}
          message="Document téléchargé"
          position="bottom"
          buttons={[
            {
              side: "end",
              icon: "close",
              text: "Fermer",
            },
          ]}
        />
      ) : clickState === "error" ? (
        <IonToast
          isOpen
          translucent
          animated
          duration={4000}
          message="Il y a eu une erreur lors du téléchargement, veuillez réessayer."
          position="bottom"
          buttons={[
            {
              side: "end",
              icon: "close",
              text: "Fermer",
            },
          ]}
        />
      ) : null}
    </div>
  )
}

export default PDFViewer
