import { IonIcon, isPlatform } from "@ionic/react"
import { useCallback, useState } from "react"

import { Tooltip, ClickAwayListener } from "@mui/material"

import { type RootState } from "stores/reducers"
import { useSelector } from "react-redux"
import { useConfig } from "hooks/config"
import questionMark from "../theme/images/icon_questionmark.svg"
import { useEventListener } from "../utils/hooks"
import "./TooltipIcon.scss"
import alertCircleOutline from "../theme/images/alert-circle-outline.svg"

interface TooltipIconProps {
  help: string
  className?: string
  noLogout?: boolean
}

const defaultProps = {
  className: "",
  noLogout: false,
}

function TooltipIcon({ help, className, noLogout }: TooltipIconProps) {
  const { data: config } = useConfig()
  const dark = useSelector((state: RootState) => state.theme.dark)

  let computedClassName = "tooltip-icon"
  if (className) {
    computedClassName += ` ${className}`
  }

  const [open, setOpen] = useState(false)

  // close tooltip on escape
  const handleClose = useCallback((e: any) => {
    if (e.key === "Escape") {
      setOpen(false)
    }
  }, [])

  useEventListener("keydown", handleClose)

  return (
    <>
      <span className="sr-only" id="tooltipInfo">
        {help}
      </span>
      {isPlatform("mobile") ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false)
          }}
        >
          <Tooltip
            title={help}
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => {
              setOpen(false)
            }}
            onOpen={() => {
              setOpen(true)
            }}
            open={open}
            disableHoverListener
            disableTouchListener
            aria-describedby="tooltipInfo"
          >
            <div // replace with button
              tabIndex={0}
              className={computedClassName}
              data-tip={help}
              data-class="tooltip-icon__tooltip"
              onFocus={() => {
                setOpen(true)
              }}
            >
              {noLogout ? (
                <IonIcon
                  icon={alertCircleOutline}
                  style={dark || !config ? undefined : { color: config.primaryColor }}
                  onClick={() => {
                    setOpen(true)
                  }}
                  aria-describedby="tooltipInfo"
                />
              ) : (
                <IonIcon
                  icon={questionMark}
                  style={dark || !config ? undefined : { color: config.primaryColor }}
                  onClick={() => {
                    setOpen(true)
                  }}
                  aria-describedby="tooltipInfo"
                />
              )}
            </div>
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip
          title={help}
          arrow
          onClose={() => {
            setOpen(false)
          }}
          onOpen={() => {
            setOpen(true)
          }}
          open={open}
        >
          <div tabIndex={0} className={computedClassName} data-tip={help} data-class="tooltip-icon__tooltip">
            {noLogout ? (
              <IonIcon
                icon={alertCircleOutline}
                style={dark || !config ? undefined : { color: config.primaryColor }}
                aria-describedby="tooltipInfo"
              />
            ) : (
              <IonIcon
                icon={questionMark}
                style={dark || !config ? undefined : { color: config.primaryColor }}
                aria-describedby="tooltipInfo"
              />
            )}
          </div>
        </Tooltip>
      )}
    </>
  )
}

TooltipIcon.defaultProps = defaultProps

export default TooltipIcon
