import { IonPage, IonContent, IonText } from "@ionic/react"
import React from "react"

import "./LostPasswordConfirmation.scss"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

import "@ionic/react/css/core.css"
import Container from "../../layouts/container/Container"
import BuildLayout from "../../layouts/BuildLayout"

function LostPasswordConfirmation() {
  return (
    <IonPage>
      <Header />
      <IonContent role="main">
        <BuildLayout>
          <Container signup>
            <IonText>
              <p className="lost-password-confirmation">
                <span className="lost-password-confirmation">Consultez votre boîte mail</span>
                <br />
                <br />
                Un e-mail vous a été envoyé pour réinitialiser votre mot de passe. Si vous ne le trouvez pas, regardez
                dans vos spams ou courriers indésirables et vérifiez l’adresse e-mail saisie.
              </p>
            </IonText>
          </Container>
        </BuildLayout>
        <Footer />
      </IonContent>
    </IonPage>
  )
}

export default LostPasswordConfirmation
